import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { AsyncOperationStatus$1, LoginRequest, Cmd_fromAsync, errorToast, IBoardApi$reflection, Route_builder } from "../../../Shared.fs.js";
import { Msg, State } from "./Types.fs.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_ofSub } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/../Fable.Elmish.3.1.0/cmd.fs.js";
import { RouterModule_nav } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { toString } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { singleton as singleton_1 } from "../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function update(msg, state) {
    let pattern_matching_result, username, password, href;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
        password = msg.fields[0];
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 2;
        href = msg.fields[0];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            if (state.Networking) {
                pattern_matching_result = 3;
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 0;
        username = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(username, state.Password, state.Networking), Cmd_none()];
        }
        case 1: {
            return [new State(state.Username, password, state.Networking), Cmd_none()];
        }
        case 2: {
            return [state, Cmd_ofSub((_arg138) => {
                RouterModule_nav(singleton(href), 1, 2);
            })];
        }
        case 3: {
            return [state, Cmd_none()];
        }
        case 4: {
            if (msg.tag === 2) {
                if (msg.fields[0].tag === 1) {
                    if (msg.fields[0].fields[0].tag === 1) {
                        return [new State(state.Username, state.Password, false), errorToast(toString(msg.fields[0].fields[0].fields[0]))];
                    }
                    else {
                        return [state, Cmd_ofSub((_arg138_1) => {
                            RouterModule_nav(singleton("/"), 1, 2);
                        })];
                    }
                }
                else {
                    return [new State(state.Username, state.Password, true), Cmd_fromAsync(singleton_1.Delay(() => {
                        const request = new LoginRequest(state.Username, state.Password);
                        return singleton_1.Bind(boardApi.Login(request), (_arg1) => singleton_1.Return(new Msg(2, new AsyncOperationStatus$1(1, _arg1))));
                    }))];
                }
            }
            else {
                throw (new Error("Match failure: Login.Types.Msg"));
            }
        }
    }
}

