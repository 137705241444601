import { isNullOrEmpty } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { createElement } from "react";
import { createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { Msg } from "./Types.fs.js";
import { singleton, cons, ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { AsyncOperationStatus$1 } from "../../../Shared.fs.js";

export function render(state, dispatch) {
    let elms_5, elms_1, elms, elms_3, elms_2, elms_4, props_10;
    const valid = ((!isNullOrEmpty(state.newPassword)) && (!isNullOrEmpty(state.newPasswordRepeat))) && (state.newPassword === state.newPasswordRepeat);
    const children_6 = singleton((elms_5 = ofArray([(elms_1 = ofArray([createElement("label", {
        children: "New Password",
    }), (elms = singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["value", state.newPassword], ["onChange", (ev) => {
        dispatch(new Msg(0, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = ofArray([createElement("label", {
        children: "Confirm New Password",
    }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["value", state.newPasswordRepeat], ["onChange", (ev_1) => {
        dispatch(new Msg(1, ev_1.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = singleton((props_10 = toList(delay(() => (state.networking ? append(singleton_1(["className", "is-loading"]), delay(() => append(singleton_1(["className", "is-link"]), delay(() => append(singleton_1(["id", "resetPasswordButton"]), delay(() => append(singleton_1(["disabled", !valid]), delay(() => append(singleton_1(["children", "Reset Password"]), delay(() => singleton_1(["onClick", (e) => {
        e.preventDefault();
        dispatch(new Msg(2, new AsyncOperationStatus$1(0)));
    }]))))))))))) : empty()))), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

