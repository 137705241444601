import { createElement } from "react";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";

export const render = (() => {
    const elms = singleton(createElement("strong", {
        children: "404 Not Found",
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
})();

