import { isCancellationRequested, cancel as cancel_1, createCancellationToken } from "../../../../Client/fable_modules/fable-library.3.7.5/Async.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { loadMorePosts, getPosts, State, Page, Msg } from "./Types.fs.js";
import { update as update_1, subscribe } from "./Comments/State.fs.js";
import { init } from "./Comments/Types.fs.js";
import { BanRequest, IPBanRequest, successToast, AsyncOperationStatusWith$2, AsyncOperationStatus$1, boardApi, AnonymousRequest$1, CaptchaToken as CaptchaToken_2, CreatePostRequest, CreatePostData, Cmd_fromCancellableAsync, errorToast, AppUser, AnonymousUser, List_last, Deferred$1, GetPostsRequest, SecureRequest$1, GetPostsParameters, GetPostsParameters_empty, Cmd_fromAsync } from "../../../Shared.fs.js";
import { getToken } from "../../../LocalStore.fs.js";
import { Cmd_ofSub } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/../Fable.Elmish.3.1.0/cmd.fs.js";
import { RouterModule_nav } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { ofArray, append, singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { config } from "../../../Config.fs.js";
import { map } from "../../../Deferred.fs.js";
import { List_distinct } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq2.js";
import { safeHash, equals } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { singleton as singleton_1 } from "../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { initialize } from "../../../../Client/fable_modules/fable-library.3.7.5/Array.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Extensions.fs.js";
import { defaultArg } from "../../../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { toString } from "../../../../Client/fable_modules/fable-library.3.7.5/Long.js";
import { toString as toString_1 } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { printf, toConsole } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";

let cts = createCancellationToken();

export function subscription(state) {
    const matchValue = state.CurrentPage;
    if (matchValue.tag === 1) {
        return Cmd_map((arg0) => (new Msg(2, arg0)), subscribe(matchValue.fields[0]));
    }
    else {
        return Cmd_none();
    }
}

export function update(msg, state) {
    let content, inputRecord, matchValue_1, content_1, inputRecord_1, matchValue_2, content_2, inputRecord_2, matchValue_4, xs, content_4, inputRecord_3, matchValue_11;
    const matchValue = [msg, state.CurrentPage];
    let pattern_matching_result, url, href, commentsMsg_1, commentsState_1;
    if (matchValue[0].tag === 0) {
        pattern_matching_result = 0;
        url = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 0) {
            pattern_matching_result = 1;
            href = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 2;
            commentsMsg_1 = matchValue[0].fields[0];
            commentsState_1 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[0].fields[0].tag === 0) {
            if (matchValue[1].tag === 0) {
                if (equals(state.Posts, new Deferred$1(1))) {
                    pattern_matching_result = 3;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            switch (url.tag) {
                case 1: {
                    const patternInput = init(url.fields[0], url.fields[1], state.CurrentUser);
                    return [new State(state.CurrentUrl, new Page(1, patternInput[0]), state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])];
                }
                case 2: {
                    return [state, Cmd_none()];
                }
                default: {
                    return [state, Cmd_fromAsync(getPosts((content = ((inputRecord = GetPostsParameters_empty(), new GetPostsParameters(url.fields[0], inputRecord.Limit, inputRecord.PreviousId, inputRecord.PreviousDate, inputRecord.UserId, inputRecord.IPAddress))), (matchValue_1 = getToken(), (matchValue_1 != null) ? (new GetPostsRequest(1, new SecureRequest$1(matchValue_1, content))) : (new GetPostsRequest(0, content))))))];
                }
            }
        }
        case 1: {
            return [state, Cmd_ofSub((_arg138) => {
                RouterModule_nav(singleton(href), 1, 2);
            })];
        }
        case 2: {
            const patternInput_1 = update_1(commentsMsg_1, commentsState_1);
            return [new State(state.CurrentUrl, new Page(1, patternInput_1[0]), state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            return [state, Cmd_none()];
        }
        case 4: {
            let pattern_matching_result_1, posts;
            if (matchValue[0].tag === 3) {
                if (matchValue[0].fields[0].tag === 1) {
                    if (matchValue[1].tag === 0) {
                        pattern_matching_result_1 = 1;
                        posts = matchValue[0].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 3;
                    }
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 3;
                }
            }
            else if (matchValue[0].tag === 4) {
                if (matchValue[0].fields[0].tag === 0) {
                    if (matchValue[1].tag === 0) {
                        if (state.Networking) {
                            pattern_matching_result_1 = 2;
                        }
                        else {
                            pattern_matching_result_1 = 3;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 3;
                    }
                }
                else {
                    pattern_matching_result_1 = 3;
                }
            }
            else {
                pattern_matching_result_1 = 3;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, new Deferred$1(1), state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_fromAsync(getPosts((content_1 = ((inputRecord_1 = GetPostsParameters_empty(), new GetPostsParameters(state.BoardId, config.PostsSize, inputRecord_1.PreviousId, inputRecord_1.PreviousDate, inputRecord_1.UserId, inputRecord_1.IPAddress))), (matchValue_2 = getToken(), (matchValue_2 != null) ? (new GetPostsRequest(1, new SecureRequest$1(matchValue_2, content_1))) : (new GetPostsRequest(0, content_1))))))];
                }
                case 1: {
                    const nextState = new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, new Deferred$1(2, posts), state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost);
                    const matchValue_3 = List_last(posts);
                    if (matchValue_3 == null) {
                        return [nextState, Cmd_none()];
                    }
                    else {
                        const entry = matchValue_3;
                        return [new State(nextState.CurrentUrl, nextState.CurrentPage, nextState.CurrentUser, nextState.BoardId, nextState.Posts, entry.Id, entry.UpdatedAt, nextState.Posting, nextState.Networking, nextState.Subject, nextState.Body, nextState.File, nextState.CaptchaToken, nextState.ViewMode, nextState.ShowCreatePost), Cmd_none()];
                    }
                }
                case 2: {
                    return [state, Cmd_none()];
                }
                case 3: {
                    let pattern_matching_result_2, posts_1, username, subject, body, file, token_3;
                    if (matchValue[0].tag === 4) {
                        if (matchValue[0].fields[0].tag === 1) {
                            if (matchValue[1].tag === 0) {
                                pattern_matching_result_2 = 1;
                                posts_1 = matchValue[0].fields[0].fields[0];
                            }
                            else {
                                pattern_matching_result_2 = 8;
                            }
                        }
                        else if (matchValue[1].tag === 0) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else if (matchValue[0].tag === 5) {
                        if (matchValue[1].tag === 0) {
                            pattern_matching_result_2 = 2;
                            username = matchValue[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else if (matchValue[0].tag === 6) {
                        if (matchValue[1].tag === 0) {
                            pattern_matching_result_2 = 3;
                            subject = matchValue[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else if (matchValue[0].tag === 7) {
                        if (matchValue[1].tag === 0) {
                            pattern_matching_result_2 = 4;
                            body = matchValue[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else if (matchValue[0].tag === 8) {
                        if (matchValue[1].tag === 0) {
                            pattern_matching_result_2 = 5;
                            file = matchValue[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else if (matchValue[0].tag === 9) {
                        if (matchValue[1].tag === 0) {
                            pattern_matching_result_2 = 6;
                            token_3 = matchValue[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else if (matchValue[0].tag === 10) {
                        if (matchValue[0].fields[0].tag === 0) {
                            if (matchValue[1].tag === 0) {
                                if (state.Posting) {
                                    pattern_matching_result_2 = 7;
                                }
                                else {
                                    pattern_matching_result_2 = 8;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 8;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 8;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 8;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, true, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_fromAsync(loadMorePosts((content_2 = ((inputRecord_2 = GetPostsParameters_empty(), new GetPostsParameters(state.BoardId, config.PostsSize, state.PreviousId, state.PreviousDate, inputRecord_2.UserId, inputRecord_2.IPAddress))), (matchValue_4 = getToken(), (matchValue_4 != null) ? (new GetPostsRequest(1, new SecureRequest$1(matchValue_4, content_2))) : (new GetPostsRequest(0, content_2))))))];
                        }
                        case 1: {
                            const nextState_1 = new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, map((currentPosts) => List_distinct(append(currentPosts, posts_1), {
                                Equals: equals,
                                GetHashCode: safeHash,
                            }), state.Posts), state.PreviousId, state.PreviousDate, state.Posting, false, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost);
                            const matchValue_5 = List_last(posts_1);
                            if (matchValue_5 == null) {
                                return [nextState_1, Cmd_none()];
                            }
                            else {
                                const entry_1 = matchValue_5;
                                return [new State(nextState_1.CurrentUrl, nextState_1.CurrentPage, nextState_1.CurrentUser, nextState_1.BoardId, nextState_1.Posts, entry_1.Id, entry_1.UpdatedAt, nextState_1.Posting, false, nextState_1.Subject, nextState_1.Body, nextState_1.File, nextState_1.CaptchaToken, nextState_1.ViewMode, nextState_1.ShowCreatePost), Cmd_none()];
                            }
                        }
                        case 2: {
                            if (state.CurrentUser.tag === 1) {
                                return [state, Cmd_none()];
                            }
                            else {
                                return [new State(state.CurrentUrl, state.CurrentPage, new AppUser(0, new AnonymousUser(username)), state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_none()];
                            }
                        }
                        case 3: {
                            return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_none()];
                        }
                        case 4: {
                            return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_none()];
                        }
                        case 5: {
                            if (file.size > config.FileSizeLimit) {
                                return [state, errorToast("File too big. Limit is 4mb.")];
                            }
                            else {
                                return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, file, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_none()];
                            }
                        }
                        case 6: {
                            return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, token_3, state.ViewMode, state.ShowCreatePost), Cmd_none()];
                        }
                        case 7: {
                            return [state, Cmd_none()];
                        }
                        case 8: {
                            let pattern_matching_result_3, post, err;
                            if (matchValue[0].tag === 10) {
                                if (matchValue[0].fields[0].tag === 1) {
                                    if (matchValue[0].fields[0].fields[0].tag === 1) {
                                        if (matchValue[1].tag === 0) {
                                            pattern_matching_result_3 = 2;
                                            err = matchValue[0].fields[0].fields[0].fields[0];
                                        }
                                        else {
                                            pattern_matching_result_3 = 6;
                                        }
                                    }
                                    else if (matchValue[1].tag === 0) {
                                        pattern_matching_result_3 = 1;
                                        post = matchValue[0].fields[0].fields[0].fields[0][0];
                                    }
                                    else {
                                        pattern_matching_result_3 = 6;
                                    }
                                }
                                else if (matchValue[1].tag === 0) {
                                    pattern_matching_result_3 = 0;
                                }
                                else {
                                    pattern_matching_result_3 = 6;
                                }
                            }
                            else if (matchValue[0].tag === 11) {
                                if (matchValue[1].tag === 0) {
                                    pattern_matching_result_3 = 3;
                                }
                                else {
                                    pattern_matching_result_3 = 6;
                                }
                            }
                            else if (matchValue[0].tag === 12) {
                                if (matchValue[1].tag === 0) {
                                    pattern_matching_result_3 = 4;
                                }
                                else {
                                    pattern_matching_result_3 = 6;
                                }
                            }
                            else if (matchValue[0].tag === 13) {
                                if (matchValue[0].fields[0].tag === 0) {
                                    if (matchValue[1].tag === 0) {
                                        if (state.Networking) {
                                            pattern_matching_result_3 = 5;
                                        }
                                        else {
                                            pattern_matching_result_3 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 6;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 6;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 6;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    cts = createCancellationToken();
                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, true, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_fromCancellableAsync(singleton_1.Delay(() => singleton_1.Bind(singleton_1.Delay(() => {
                                        const matchValue_7 = state.File;
                                        if (matchValue_7 == null) {
                                            const bytes_1 = initialize(0, (value) => (value & 0xFF), Uint8Array);
                                            return singleton_1.Return([bytes_1, void 0]);
                                        }
                                        else {
                                            const f = matchValue_7;
                                            return singleton_1.Bind(Browser_Types_File__File_ReadAsByteArray(f), (_arg1) => singleton_1.Return([_arg1, f.name]));
                                        }
                                    }), (_arg2) => {
                                        const content_3 = new CreatePostData(state.Subject, state.BoardId, state.Body, _arg2[1]);
                                        let req;
                                        const matchValue_8 = state.CurrentUser;
                                        if (matchValue_8.tag === 1) {
                                            const matchValue_9 = getToken();
                                            if (matchValue_9 == null) {
                                                throw (new Error("Logged in but no token in localstore"));
                                            }
                                            else {
                                                req = (new CreatePostRequest(1, new SecureRequest$1(matchValue_9, content_3)));
                                            }
                                        }
                                        else {
                                            req = (new CreatePostRequest(0, new AnonymousRequest$1(matchValue_8.fields[0].Username, defaultArg(state.CaptchaToken, new CaptchaToken_2(0, "")), content_3)));
                                        }
                                        return singleton_1.Bind(boardApi.CreatePost(req, _arg2[0], cts), (_arg3) => singleton_1.Return(new Msg(10, new AsyncOperationStatus$1(1, _arg3))));
                                    })), cts)];
                                }
                                case 1: {
                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, false, state.Networking, "", "", void 0, void 0, state.ViewMode, state.ShowCreatePost), (xs = ["boards", toString(state.BoardId), "posts", toString(post.Id)], Cmd_ofSub((_arg84) => {
                                        RouterModule_nav(ofArray(xs), 1, 2);
                                    }))];
                                }
                                case 2: {
                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, false, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), errorToast(toString_1(err))];
                                }
                                case 3: {
                                    return [state, Cmd_fromAsync(singleton_1.Delay(() => {
                                        cancel_1(cts);
                                        return singleton_1.Return(new Msg(12));
                                    }))];
                                }
                                case 4: {
                                    const arg10 = isCancellationRequested(cts);
                                    toConsole(printf("Cancel Requested %b"))(arg10);
                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, false, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_none()];
                                }
                                case 5: {
                                    return [state, Cmd_none()];
                                }
                                case 6: {
                                    let pattern_matching_result_4, postId, err_1;
                                    if (matchValue[0].tag === 13) {
                                        if (matchValue[0].fields[0].tag === 1) {
                                            if (matchValue[0].fields[0].fields[0].tag === 1) {
                                                if (matchValue[1].tag === 0) {
                                                    pattern_matching_result_4 = 2;
                                                    err_1 = matchValue[0].fields[0].fields[0].fields[0];
                                                }
                                                else {
                                                    pattern_matching_result_4 = 4;
                                                }
                                            }
                                            else if (matchValue[1].tag === 0) {
                                                pattern_matching_result_4 = 1;
                                            }
                                            else {
                                                pattern_matching_result_4 = 4;
                                            }
                                        }
                                        else if (matchValue[1].tag === 0) {
                                            pattern_matching_result_4 = 0;
                                            postId = matchValue[0].fields[0].fields[0];
                                        }
                                        else {
                                            pattern_matching_result_4 = 4;
                                        }
                                    }
                                    else if (matchValue[0].tag === 14) {
                                        if (matchValue[0].fields[0].tag === 0) {
                                            if (matchValue[1].tag === 0) {
                                                if (state.Networking) {
                                                    pattern_matching_result_4 = 3;
                                                }
                                                else {
                                                    pattern_matching_result_4 = 4;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_4 = 4;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_4 = 4;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 4;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, true, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_fromAsync(singleton_1.Delay(() => {
                                                const matchValue_10 = getToken();
                                                if (matchValue_10 == null) {
                                                    return singleton_1.Return(new Msg(13, new AsyncOperationStatusWith$2(1, new FSharpResult$2(0, void 0))));
                                                }
                                                else {
                                                    const request_5 = new SecureRequest$1(matchValue_10, postId);
                                                    return singleton_1.Bind(boardApi.RemovePost(request_5), (_arg4) => singleton_1.Return(new Msg(13, new AsyncOperationStatusWith$2(1, _arg4))));
                                                }
                                            }))];
                                        }
                                        case 1: {
                                            return [state, Cmd_batch(ofArray([Cmd_fromAsync(getPosts((content_4 = ((inputRecord_3 = GetPostsParameters_empty(), new GetPostsParameters(state.BoardId, config.PostsSize, inputRecord_3.PreviousId, inputRecord_3.PreviousDate, inputRecord_3.UserId, inputRecord_3.IPAddress))), (matchValue_11 = getToken(), (matchValue_11 != null) ? (new GetPostsRequest(1, new SecureRequest$1(matchValue_11, content_4))) : (new GetPostsRequest(0, content_4)))))), successToast("Post Removed")]))];
                                        }
                                        case 2: {
                                            return [state, errorToast(err_1)];
                                        }
                                        case 3: {
                                            return [state, Cmd_none()];
                                        }
                                        case 4: {
                                            let pattern_matching_result_5, endDate, ipAddress, err_2, viewMode, show;
                                            if (matchValue[0].tag === 14) {
                                                if (matchValue[0].fields[0].tag === 1) {
                                                    if (matchValue[0].fields[0].fields[0].tag === 1) {
                                                        if (matchValue[1].tag === 0) {
                                                            pattern_matching_result_5 = 2;
                                                            err_2 = matchValue[0].fields[0].fields[0].fields[0];
                                                        }
                                                        else {
                                                            pattern_matching_result_5 = 5;
                                                        }
                                                    }
                                                    else if (matchValue[1].tag === 0) {
                                                        pattern_matching_result_5 = 1;
                                                    }
                                                    else {
                                                        pattern_matching_result_5 = 5;
                                                    }
                                                }
                                                else if (matchValue[1].tag === 0) {
                                                    pattern_matching_result_5 = 0;
                                                    endDate = matchValue[0].fields[0].fields[0][1];
                                                    ipAddress = matchValue[0].fields[0].fields[0][0];
                                                }
                                                else {
                                                    pattern_matching_result_5 = 5;
                                                }
                                            }
                                            else if (matchValue[0].tag === 15) {
                                                if (matchValue[1].tag === 0) {
                                                    pattern_matching_result_5 = 3;
                                                    viewMode = matchValue[0].fields[0];
                                                }
                                                else {
                                                    pattern_matching_result_5 = 5;
                                                }
                                            }
                                            else if (matchValue[0].tag === 16) {
                                                if (matchValue[1].tag === 0) {
                                                    pattern_matching_result_5 = 4;
                                                    show = matchValue[0].fields[0];
                                                }
                                                else {
                                                    pattern_matching_result_5 = 5;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_5 = 5;
                                            }
                                            switch (pattern_matching_result_5) {
                                                case 0: {
                                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, true, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, state.ShowCreatePost), Cmd_fromAsync(singleton_1.Delay(() => {
                                                        const matchValue_12 = getToken();
                                                        if (matchValue_12 == null) {
                                                            return singleton_1.Return(new Msg(14, new AsyncOperationStatusWith$2(1, new FSharpResult$2(0, void 0))));
                                                        }
                                                        else {
                                                            const request_7 = new SecureRequest$1(matchValue_12, new BanRequest(0, new IPBanRequest(ipAddress, endDate)));
                                                            return singleton_1.Bind(boardApi.Ban(request_7), (_arg5) => singleton_1.Return(new Msg(14, new AsyncOperationStatusWith$2(1, _arg5))));
                                                        }
                                                    }))];
                                                }
                                                case 1: {
                                                    return [state, successToast("IP Banned")];
                                                }
                                                case 2: {
                                                    return [state, errorToast(err_2)];
                                                }
                                                case 3: {
                                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, viewMode, state.ShowCreatePost), Cmd_none()];
                                                }
                                                case 4: {
                                                    return [new State(state.CurrentUrl, state.CurrentPage, state.CurrentUser, state.BoardId, state.Posts, state.PreviousId, state.PreviousDate, state.Posting, state.Networking, state.Subject, state.Body, state.File, state.CaptchaToken, state.ViewMode, show), Cmd_none()];
                                                }
                                                case 5: {
                                                    return [state, Cmd_none()];
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

