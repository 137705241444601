import { Union, Record } from "../../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { GetCommentsRequest, Cmd_fromAsync, Deferred$1, GetCommentsParameters, GetCommentsParameters_empty, SecureRequest$1, GetUserRequest, AsyncOperationStatusWith$2, NetworkError, AsyncOperationStatus$1, IBoardApi$reflection, Route_builder, AsyncOperationStatusWith$2$reflection, NetworkError$reflection, AsyncOperationStatus$1$reflection, User$reflection, CaptchaToken$reflection, Deferred$1$reflection, Comment$$reflection, AppUser$reflection } from "../../../../Shared.fs.js";
import { tuple_type, unit_type, union_type, record_type, bool_type, string_type, option_type, list_type, class_type } from "../../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { FSharpResult$2 } from "../../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "../../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { printf, toConsole } from "../../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { singleton } from "../../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { getToken } from "../../../../LocalStore.fs.js";
import { config } from "../../../../Config.fs.js";

export class State extends Record {
    constructor(CurrentUser, PostId, Comments, PreviousId, PreviousDate, Body, File, CaptchaToken, ReplyTo, Networking, Posting, ShowUserPanel, PanelUser) {
        super();
        this.CurrentUser = CurrentUser;
        this.PostId = PostId;
        this.Comments = Comments;
        this.PreviousId = PreviousId;
        this.PreviousDate = PreviousDate;
        this.Body = Body;
        this.File = File;
        this.CaptchaToken = CaptchaToken;
        this.ReplyTo = ReplyTo;
        this.Networking = Networking;
        this.Posting = Posting;
        this.ShowUserPanel = ShowUserPanel;
        this.PanelUser = PanelUser;
    }
}

export function State$reflection() {
    return record_type("Comments.Types.State", [], State, () => [["CurrentUser", AppUser$reflection()], ["PostId", class_type("System.Int64")], ["Comments", Deferred$1$reflection(list_type(Comment$$reflection()))], ["PreviousId", option_type(class_type("System.Int64"))], ["PreviousDate", option_type(class_type("System.DateTimeOffset"))], ["Body", string_type], ["File", option_type(class_type("Browser.Types.File"))], ["CaptchaToken", option_type(CaptchaToken$reflection())], ["ReplyTo", option_type(Comment$$reflection())], ["Networking", bool_type], ["Posting", bool_type], ["ShowUserPanel", bool_type], ["PanelUser", Deferred$1$reflection(User$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NavigateTo", "GetComments", "LoadMoreComments", "SetUsername", "SetBody", "SetFile", "SetCaptchaToken", "CreateComment", "CancelPost", "PostCancelled", "RemoveComment", "SetReply", "ToggleUserPanel", "GetUserDetails", "BanUser", "BanIP"];
    }
}

export function Msg$reflection() {
    return union_type("Comments.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(list_type(Comment$$reflection()))]], [["Item", AsyncOperationStatus$1$reflection(list_type(Comment$$reflection()))]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("Browser.Types.File")]], [["Item", CaptchaToken$reflection()]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Comment$$reflection(), NetworkError$reflection()], FSharpResult$2, () => [[["ResultValue", Comment$$reflection()]], [["ErrorValue", NetworkError$reflection()]]]))]], [], [], [["Item", AsyncOperationStatusWith$2$reflection(class_type("System.Int64"), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", option_type(Comment$$reflection())]], [], [["Item", AsyncOperationStatusWith$2$reflection(class_type("System.Int64"), union_type("Microsoft.FSharp.Core.FSharpResult`2", [User$reflection(), NetworkError$reflection()], FSharpResult$2, () => [[["ResultValue", User$reflection()]], [["ErrorValue", NetworkError$reflection()]]]))]], [["Item", AsyncOperationStatusWith$2$reflection(class_type("System.Int64"), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatusWith$2$reflection(tuple_type(string_type, option_type(class_type("System.DateTimeOffset"))), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function scrollToBottom() {
    const arg10 = document.body.scrollHeight;
    toConsole(printf("Scroll Height %f"))(arg10);
    window.scrollTo(0, document.body.scrollHeight);
}

export function getComments(req) {
    return singleton.Delay(() => singleton.Bind(boardApi.GetComments(req), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, _arg1)))));
}

export function getMoreComments(req) {
    return singleton.Delay(() => singleton.Bind(boardApi.GetComments(req), (_arg1) => singleton.Return(new Msg(2, new AsyncOperationStatus$1(1, _arg1)))));
}

export function getUser(userId) {
    return singleton.Delay(() => {
        const matchValue = getToken();
        if (matchValue == null) {
            return singleton.Return(new Msg(13, new AsyncOperationStatusWith$2(1, new FSharpResult$2(1, new NetworkError(0)))));
        }
        else {
            const request = new SecureRequest$1(matchValue, new GetUserRequest(userId));
            return singleton.Bind(boardApi.GetUser(request), (_arg1) => singleton.Return(new Msg(13, new AsyncOperationStatusWith$2(1, _arg1))));
        }
    });
}

export function init(postId, commentId, user) {
    let matchValue;
    let content;
    const inputRecord = GetCommentsParameters_empty();
    content = (new GetCommentsParameters(postId, commentId, config.CommentsSize, inputRecord.PreviousId, inputRecord.PreviousDate, inputRecord.UserId, inputRecord.IPAddress));
    return [new State(user, postId, new Deferred$1(0), void 0, void 0, "", void 0, void 0, void 0, false, false, false, new Deferred$1(0)), Cmd_fromAsync(getComments((user.tag === 1) ? ((matchValue = getToken(), (matchValue == null) ? (() => {
        throw (new Error("Logged in but no token in localstore"));
    })() : (new GetCommentsRequest(1, new SecureRequest$1(matchValue, content))))) : (new GetCommentsRequest(0, content))))];
}

