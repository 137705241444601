import { Cmd_batch, Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { RemoveUserRoleRequest, AssignUserRoleRequest, AsyncOperationStatusWith$2, UserModule_Role_parse_Z721C83C5, successToast, errorToast, boardApi, SecureRequest$1, AsyncOperationStatus$1, NetworkError, SecureTokenError, Cmd_fromAsync, Deferred$1 } from "../../../Shared.fs.js";
import { Msg, State } from "./Types.fs.js";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { getToken } from "../../../LocalStore.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { toString } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { Cmd_OfFunc_result } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { equals } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";

export function update(msg, state) {
    let pattern_matching_result;
    if (msg.tag === 0) {
        if (msg.fields[0].tag === 0) {
            if (equals(state.Users, new Deferred$1(1))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [state, Cmd_none()];
        }
        case 1: {
            let pattern_matching_result_1, users, err, search;
            if (msg.tag === 0) {
                if (msg.fields[0].tag === 1) {
                    if (msg.fields[0].fields[0].tag === 1) {
                        pattern_matching_result_1 = 2;
                        err = msg.fields[0].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                        users = msg.fields[0].fields[0].fields[0];
                    }
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (msg.tag === 1) {
                pattern_matching_result_1 = 3;
                search = msg.fields[0];
            }
            else if (msg.tag === 2) {
                if (msg.fields[0].tag === 0) {
                    if (state.Networking) {
                        pattern_matching_result_1 = 4;
                    }
                    else {
                        pattern_matching_result_1 = 5;
                    }
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else {
                pattern_matching_result_1 = 5;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new State(state.CurrentUser, new Deferred$1(1), state.Search, state.Networking, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_fromAsync(singleton.Delay(() => {
                        const matchValue = getToken();
                        if (matchValue == null) {
                            return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new NetworkError(3, new SecureTokenError(0, "invalid token"))))));
                        }
                        else {
                            const req = new SecureRequest$1(matchValue, void 0);
                            return singleton.Bind(boardApi.GetUsers(req), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, _arg1))));
                        }
                    }))];
                }
                case 1: {
                    return [new State(state.CurrentUser, new Deferred$1(2, users), state.Search, state.Networking, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_none()];
                }
                case 2: {
                    return [state, errorToast(toString(err))];
                }
                case 3: {
                    return [new State(state.CurrentUser, state.Users, search, state.Networking, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_none()];
                }
                case 4: {
                    return [state, Cmd_none()];
                }
                case 5: {
                    if (msg.tag === 2) {
                        if (msg.fields[0].tag === 1) {
                            if (msg.fields[0].fields[0].tag === 1) {
                                return [new State(state.CurrentUser, state.Users, state.Search, false, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), errorToast(toString(msg.fields[0].fields[0].fields[0]))];
                            }
                            else {
                                return [new State(state.CurrentUser, state.Users, state.Search, false, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0))), successToast("Assigned Role")]))];
                            }
                        }
                        else if (UserModule_Role_parse_Z721C83C5(msg.fields[0].fields[0][1]) != null) {
                            return [new State(state.CurrentUser, state.Users, state.Search, true, false, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_fromAsync(singleton.Delay(() => {
                                const matchValue_1 = getToken();
                                if (matchValue_1 == null) {
                                    return singleton.Return(new Msg(2, new AsyncOperationStatusWith$2(1, new FSharpResult$2(1, new NetworkError(3, new SecureTokenError(0, "invalid token"))))));
                                }
                                else {
                                    const req_1 = new SecureRequest$1(matchValue_1, new AssignUserRoleRequest(msg.fields[0].fields[0][0], msg.fields[0].fields[0][1]));
                                    return singleton.Bind(boardApi.AssignUserRole(req_1), (_arg2) => singleton.Return(new Msg(2, new AsyncOperationStatusWith$2(1, _arg2))));
                                }
                            }))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
                    else if (msg.tag === 3) {
                        if (msg.fields[0].tag === 1) {
                            if (msg.fields[0].fields[0].tag === 1) {
                                return [new State(state.CurrentUser, state.Users, state.Search, false, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), errorToast(toString(msg.fields[0].fields[0].fields[0]))];
                            }
                            else {
                                return [new State(state.CurrentUser, state.Users, state.Search, false, state.ShowUserMenu, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0))), successToast("Removed Role")]))];
                            }
                        }
                        else if (UserModule_Role_parse_Z721C83C5(msg.fields[0].fields[0][1]) != null) {
                            return [new State(state.CurrentUser, state.Users, state.Search, true, false, state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_fromAsync(singleton.Delay(() => {
                                const matchValue_2 = getToken();
                                if (matchValue_2 == null) {
                                    return singleton.Return(new Msg(2, new AsyncOperationStatusWith$2(1, new FSharpResult$2(1, new NetworkError(3, new SecureTokenError(0, "invalid token"))))));
                                }
                                else {
                                    const req_2 = new SecureRequest$1(matchValue_2, new RemoveUserRoleRequest(msg.fields[0].fields[0][0], msg.fields[0].fields[0][1]));
                                    return singleton.Bind(boardApi.RemoveUserRole(req_2), (_arg3) => singleton.Return(new Msg(2, new AsyncOperationStatusWith$2(1, _arg3))));
                                }
                            }))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
                    else if (msg.tag === 4) {
                        return [new State(state.CurrentUser, state.Users, state.Search, state.Networking, msg.fields[0], state.SelectedUser, state.BanDate, state.IndefiniteBan), Cmd_none()];
                    }
                    else if (msg.tag === 5) {
                        return [new State(state.CurrentUser, state.Users, state.Search, state.Networking, state.ShowUserMenu, msg.fields[0], state.BanDate, state.IndefiniteBan), Cmd_none()];
                    }
                    else {
                        throw (new Error("Match failure: Admin.Types.Msg"));
                    }
                }
            }
        }
    }
}

