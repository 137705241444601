import { parseUrl, Msg } from "./Types.fs.js";
import { RouterModule_router, RouterModule_encodeParts } from "../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { replace } from "../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { map, ofArray, singleton } from "../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { toString } from "../../../Client/fable_modules/fable-library.3.7.5/Long.js";
import { createElement } from "react";
import { createObj } from "../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { singleton as singleton_1, append, delay, toList } from "../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { UserModule_Role, User__InRole_7B3101B3 } from "../../Shared.fs.js";
import { Interop_reactApi } from "../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { render as render_1 } from "./Login/View.fs.js";
import { render as render_2 } from "./ForgotPassword/View.fs.js";
import { render as render_3 } from "./ResetPassword/View.fs.js";
import { render as render_4 } from "./EmailVerified/View.fs.js";
import { render as render_5 } from "./Profile/View.fs.js";
import { render as render_6 } from "./Posts/View.fs.js";
import { render as render_7 } from "./Posts/Comments/View.fs.js";
import { render as render_8 } from "./NotFound/View.fs.js";
import { render as render_9 } from "./Admin/View.fs.js";
import { render as render_10 } from "./Signup/View.fs.js";

export function goToUrl(dispatch, href, e) {
    e.preventDefault();
    dispatch(new Msg(10, href));
}

export function linkHref(href) {
    return RouterModule_encodeParts(singleton(replace(href, "/", " ").trim()), 2);
}

export function postHref(boardId) {
    return RouterModule_encodeParts(ofArray(["boards", toString(boardId)]), 2);
}

export function nav(state, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["id", "nav"], (elems = toList(delay(() => append(singleton_1(createElement("a", {
        children: "[home]",
        href: linkHref("/"),
    })), delay(() => {
        let href_1, href;
        const matchValue = state.CurrentUser;
        if (matchValue.tag === 1) {
            const user = matchValue.fields[0];
            return append(User__InRole_7B3101B3(user, new UserModule_Role(2)) ? singleton_1(createElement("a", {
                children: "[dashboard]",
                href: linkHref("admin"),
                onClick: (href_1 = linkHref("admin"), (e_1) => {
                    goToUrl(dispatch, href_1, e_1);
                }),
            })) : singleton_1(null), delay(() => {
                let href_2;
                return append(singleton_1(createElement("a", {
                    children: `[${user.Username}]`,
                    href: linkHref("profile"),
                    onClick: (href_2 = linkHref("profile"), (e_2) => {
                        goToUrl(dispatch, href_2, e_2);
                    }),
                })), delay(() => {
                    let href_3;
                    return singleton_1(createElement("a", {
                        children: "[logout]",
                        href: linkHref("logout"),
                        onClick: (href_3 = linkHref("logout"), (e_3) => {
                            goToUrl(dispatch, href_3, e_3);
                        }),
                    }));
                }));
            }));
        }
        else {
            return singleton_1(createElement("a", {
                children: "[login]",
                href: linkHref("login"),
                onClick: (href = linkHref("login"), (e) => {
                    goToUrl(dispatch, href, e);
                }),
            }));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export const rulesText = "\n    To access vgchan, you understand and agree to the following:\n\n    The content of this website is for mature audiences only and may not be suitable for minors.\n    If you are a minor or it is illegal for you to access mature images and language, do not proceed.\n\n    This website is presented to you as is, with no warranty, express or implied.\n    By clicking \u0027I Agree,\u0027 you agree not to hold vgchan responsible for any damages from your use of the\n    website and you understand that the content posted is not owned or generated by vgchan, but rather by vgchan\u0027s users.\n\n    As a condition of using this website, you agree to comply with the \u0027Rules\u0027 which are listed below.\n    Please read the Rules carefully. Any users found not following the Rules may result in a Ban.\n\n    Rules:\n\n        1. Only Video Game related content is allowed.\n        2. No porn or nudity of any kind.\n        3. No illegal content of any kind.\n\n\n    We hope you enjoy the use of this website dedicated solely for people who love Video Games.\n";

export function index(state, dispatch) {
    const elms = toList(delay(() => {
        let matchValue, rows, props_8, elems, children_6, children_4, children_10, properties_5, elems_1;
        return state.AgreedToRules ? singleton_1((matchValue = state.Boards, (matchValue.tag === 1) ? createElement("h1", {
            children: "Loading...",
        }) : ((matchValue.tag === 2) ? ((rows = map((board) => {
            let children, href;
            const children_2 = singleton((children = singleton(createElement("a", {
                href: postHref(board.Id),
                onClick: (href = postHref(board.Id), (e) => {
                    goToUrl(dispatch, href, e);
                }),
                children: board.Name,
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })));
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            });
        }, matchValue.fields[0]), (props_8 = ofArray([["className", "is-fullwidth"], (elems = [(children_6 = singleton((children_4 = singleton(createElement("th", {
            children: "Boards",
        })), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(rows)),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("table", createObj(Helpers_combineClasses("table", props_8)))))) : null))) : singleton_1((children_10 = ofArray([createElement("pre", {
            children: rulesText,
        }), createElement("div", createObj(ofArray([["className", "row"], (properties_5 = ofArray([["marginTop", 16 + "px"], ["justifyContent", "center"]]), ["style", createObj(properties_5)]), (elems_1 = [createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (e_1) => {
            dispatch(new Msg(12, true));
        }], ["children", "I Agree"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        })));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function render(state, dispatch) {
    let children_2, elms, elms_1, props_1;
    let activePage;
    const matchValue = state.CurrentPage;
    switch (matchValue.tag) {
        case 1: {
            activePage = render_1(matchValue.fields[0], (arg_1) => {
                dispatch(new Msg(2, arg_1));
            });
            break;
        }
        case 2: {
            activePage = render_2(matchValue.fields[0], (arg_2) => {
                dispatch(new Msg(3, arg_2));
            });
            break;
        }
        case 3: {
            activePage = render_3(matchValue.fields[0], (arg_3) => {
                dispatch(new Msg(4, arg_3));
            });
            break;
        }
        case 4: {
            activePage = render_4(matchValue.fields[0]);
            break;
        }
        case 6: {
            activePage = render_5(matchValue.fields[0], (arg_4) => {
                dispatch(new Msg(6, arg_4));
            });
            break;
        }
        case 8: {
            activePage = render_6(matchValue.fields[0], (arg_5) => {
                dispatch(new Msg(5, arg_5));
            });
            break;
        }
        case 9: {
            activePage = render_7(matchValue.fields[0], (arg_6) => {
                dispatch(new Msg(8, arg_6));
            });
            break;
        }
        case 0: {
            activePage = index(state, dispatch);
            break;
        }
        case 7: {
            activePage = render_8;
            break;
        }
        case 10: {
            activePage = render_9(matchValue.fields[0], (arg_7) => {
                dispatch(new Msg(7, arg_7));
            });
            break;
        }
        default: {
            activePage = render_10(matchValue.fields[0], (arg) => {
                dispatch(new Msg(1, arg));
            });
        }
    }
    return RouterModule_router({
        hashMode: 2,
        onUrlChanged: (arg_9) => {
            dispatch(new Msg(9, parseUrl(arg_9)));
        },
        application: (children_2 = ofArray([(elms = singleton(nav(state, dispatch)), createElement("div", {
            className: "container",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton((props_1 = ofArray([["className", "is-12"], ["children", Interop_reactApi.Children.toArray([activePage])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })),
    });
}

