import { RouterModule_encodeParts } from "../../../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { append as append_1, item, length, map, empty as empty_1, singleton as singleton_1, ofArray } from "../../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { toLocalTime as toLocalTime_1, toShortTimeString, toShortDateString, dayOfWeek, fromDateTimeOffset } from "../../../../../Client/fable_modules/fable-library.3.7.5/Date.js";
import { Msg } from "./Types.fs.js";
import { empty, singleton, append, delay, toList } from "../../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { createElement } from "react";
import { createObj } from "../../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { Interop_reactApi } from "../../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { join, endsWith, printf } from "../../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { AsyncOperationStatus$1, UserModule_Role, User__InRole_7B3101B3, AsyncOperationStatusWith$2, formatDistance } from "../../../../Shared.fs.js";
import { toString, equals } from "../../../../../Client/fable_modules/fable-library.3.7.5/Long.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../../../../Client/fable_modules/Feliz.1.56.0/React.fs.js";
import { toLocalTime } from "../../../../../Client/fable_modules/fable-library.3.7.5/DateOffset.js";
import { defaultArg } from "../../../../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { ComposeModule_compose } from "../../../../Components/./Compose.fs.js";

export function commentHref(boardId, postId, commentId) {
    return RouterModule_encodeParts(ofArray(["boards", postId, "posts", postId, "comments", commentId]), 2);
}

export function boardHRef(boardId) {
    return RouterModule_encodeParts(ofArray(["boards", boardId]), 2);
}

export function postHRef(boardId, postId) {
    return RouterModule_encodeParts(ofArray(["boards", boardId, "posts", postId]), 2);
}

export function formatDay(date) {
    let matchValue;
    let copyOfStruct = fromDateTimeOffset(date, 0);
    matchValue = dayOfWeek(copyOfStruct);
    switch (matchValue) {
        case 0: {
            return "Sunday";
        }
        case 1: {
            return "Monday";
        }
        case 2: {
            return "Tuesday";
        }
        case 3: {
            return "Wednesday";
        }
        case 4: {
            return "Thursday";
        }
        case 5: {
            return "Friday";
        }
        case 6: {
            return "Saturday";
        }
        default: {
            return "";
        }
    }
}

export function innerHtml(html) {
    return ["dangerouslySetInnerHTML", {
        __html: html,
    }];
}

export function goToUrl(dispatch, href, e) {
    e.preventDefault();
    dispatch(new Msg(0, href));
}

export function userPanel(state, dispatch) {
    const props_19 = toList(delay(() => append(state.ShowUserPanel ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_7;
        return singleton((elems_7 = toList(delay(() => {
            let elms;
            const matchValue = state.PanelUser;
            switch (matchValue.tag) {
                case 1: {
                    return singleton(createElement("div", {
                        children: "Loading...",
                    }));
                }
                case 2: {
                    const user = matchValue.fields[0];
                    return append(singleton((elms = ofArray([createElement("div", {
                        children: [user.Username],
                    }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg1) => {
                        dispatch(new Msg(12));
                    }]))))]), createElement("header", {
                        className: "quickview-header",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))), delay(() => {
                        let elms_5, elms_1, fmt, elms_2, fmt_1, elms_3, fmt_2, elms_4;
                        return append(singleton((elms_5 = ofArray([createElement("br", {}), (elms_1 = singleton_1(createElement("strong", createObj(singleton_1(((fmt = printf("User Id:  %i"), fmt.cont((arg00) => ["children", arg00])))(user.Id))))), createElement("p", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        })), (elms_2 = singleton_1(createElement("strong", createObj(singleton_1(((fmt_1 = printf("Created %s"), fmt_1.cont((arg00_1) => ["children", arg00_1])))(formatDistance(user.CreatedAt)))))), createElement("p", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                        })), (elms_3 = singleton_1(createElement("strong", createObj(singleton_1(((fmt_2 = printf("%s"), fmt_2.cont((arg00_2) => ["children", arg00_2])))(user.IsBanned ? "Banned" : ""))))), createElement("p", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                        })), (elms_4 = toList(delay(() => {
                            let fmt_3;
                            const matchValue_1 = user.Email;
                            if (matchValue_1 == null) {
                                return singleton(null);
                            }
                            else {
                                const email = matchValue_1;
                                return append(singleton(createElement("strong", createObj(singleton_1(((fmt_3 = printf("Email: %s"), fmt_3.cont((arg00_3) => ["children", arg00_3])))(email))))), delay(() => {
                                    let fmt_4;
                                    return singleton(createElement("strong", createObj(singleton_1(((fmt_4 = printf("(%s)"), fmt_4.cont((arg00_4) => ["children", arg00_4])))(user.EmailVerified ? "verified" : "unverified")))));
                                }));
                            }
                        })), createElement("p", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                        }))]), createElement("div", {
                            className: "quickview-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                        }))), delay(() => {
                            let elms_6;
                            return singleton((elms_6 = toList(delay(() => {
                                const matchValue_2 = state.CurrentUser;
                                return (matchValue_2.tag === 0) ? singleton(null) : ((!equals(matchValue_2.fields[0].Id, user.Id)) ? singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Ban"], ["onClick", (e) => {
                                    e.preventDefault();
                                    dispatch(new Msg(14, new AsyncOperationStatusWith$2(0, user.Id)));
                                }]]))))) : singleton(null));
                            })), createElement("footer", {
                                className: "quickview-footer",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                            })));
                        }));
                    }));
                }
                default: {
                    return singleton(null);
                }
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]));
    }))));
    return createElement("div", createObj(Helpers_combineClasses("quickview", props_19)));
}

export function RenderComment(renderCommentInputProps) {
    let elms_5, elms_2, children_14, elm, elms_4;
    const dispatch = renderCommentInputProps.dispatch;
    const state = renderCommentInputProps.state;
    const comment = renderCommentInputProps.comment;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const minimize = patternInput[0];
    const elms_6 = singleton_1((elms_5 = ofArray([(elms_2 = singleton_1((children_14 = toList(delay(() => {
        let matchValue_1, userId;
        return append((matchValue_1 = [state.CurrentUser, comment.UserId], (matchValue_1[0].tag === 1) ? ((matchValue_1[1] != null) ? ((userId = matchValue_1[1], singleton(createElement("a", {
            children: comment.Username,
            onClick: (e_2) => {
                e_2.preventDefault();
                dispatch(new Msg(13, new AsyncOperationStatusWith$2(0, userId)));
                dispatch(new Msg(12));
            },
        })))) : singleton(createElement("strong", {
            children: comment.Username,
        }))) : singleton(createElement("strong", {
            children: comment.Username,
        }))), delay(() => {
            let elems_3, copyOfStruct_1, copyOfStruct, copyOfStruct_3, copyOfStruct_2, href;
            return append(singleton(createElement("span", createObj(singleton_1((elems_3 = [` ${((copyOfStruct_1 = ((copyOfStruct = toLocalTime(comment.CreatedAt), fromDateTimeOffset(copyOfStruct, 0))), toShortDateString(copyOfStruct_1)))} (${formatDay(comment.CreatedAt)}) ${((copyOfStruct_3 = ((copyOfStruct_2 = toLocalTime(comment.CreatedAt), fromDateTimeOffset(copyOfStruct_2, 0))), toShortTimeString(copyOfStruct_3)))}`, createElement("a", {
                children: `#${comment.Id}`,
                href: commentHref(toString(comment.Board.Id), toString(comment.PostId), toString(comment.Id)),
                onClick: (href = commentHref(toString(comment.Board.Id), toString(comment.PostId), toString(comment.Id)), (e_3) => {
                    goToUrl(dispatch, href, e_3);
                }),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))))), delay(() => append(singleton(createElement("br", {})), delay(() => {
                let matchValue_2, reply, elms_1, children_6, href_1, elms;
                return append((matchValue_2 = comment.Reply, (matchValue_2 == null) ? singleton(null) : ((reply = matchValue_2, singleton((elms_1 = ofArray([(children_6 = singleton_1(createElement("a", {
                    children: `>>${reply.Id}`,
                    href: commentHref(toString(comment.Board.Id), toString(comment.PostId), toString(reply.Id)),
                    onClick: (href_1 = commentHref(toString(comment.Board.Id), toString(comment.PostId), toString(reply.Id)), (e_4) => {
                        goToUrl(dispatch, href_1, e_4);
                    }),
                    className: "is-popover-trigger",
                })), createElement("p", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                })), (elms = toList(delay(() => append(singleton(createElement("strong", {
                    children: `${reply.Username}`,
                })), delay(() => {
                    let copyOfStruct_5, copyOfStruct_4, copyOfStruct_7, copyOfStruct_6;
                    return append(singleton(createElement("span", {
                        children: ` ${((copyOfStruct_5 = ((copyOfStruct_4 = fromDateTimeOffset(reply.CreatedAt, 0), toLocalTime_1(copyOfStruct_4))), toShortDateString(copyOfStruct_5)))} (${formatDay(reply.CreatedAt)}) #${reply.Id} ${((copyOfStruct_7 = ((copyOfStruct_6 = toLocalTime(reply.CreatedAt), fromDateTimeOffset(copyOfStruct_6, 0))), toShortTimeString(copyOfStruct_7)))}`,
                    })), delay(() => {
                        let matchValue_3, replyId, children_8;
                        return append((matchValue_3 = reply.ReplyId, (matchValue_3 == null) ? singleton(null) : ((replyId = matchValue_3, singleton((children_8 = singleton_1(createElement("a", {
                            children: `>>${replyId}`,
                        })), createElement("p", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                        })))))), delay(() => append(singleton(createElement("p", {
                            dangerouslySetInnerHTML: {
                                __html: reply.Body,
                            },
                        })), delay(() => {
                            const matchValue_4 = reply.FileUrl;
                            if (matchValue_4 == null) {
                                return singleton(null);
                            }
                            else {
                                const url = matchValue_4;
                                return singleton(createElement("img", {
                                    src: url,
                                }));
                            }
                        }))));
                    }));
                })))), createElement("div", {
                    className: "popover-content",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))]), createElement("div", {
                    className: "popover",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })))))), delay(() => append(singleton(createElement("p", createObj(singleton_1(innerHtml(comment.Body))))), delay(() => {
                    let matchValue_5, url_1, elems_6, children_12;
                    return append((matchValue_5 = comment.FileUrl, (matchValue_5 == null) ? singleton(null) : ((url_1 = matchValue_5, endsWith(url_1.toLocaleLowerCase(), ".webm") ? singleton(createElement("video", createObj(ofArray([["controls", true], (elems_6 = [createElement("source", {
                        src: url_1,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))) : singleton((children_12 = singleton_1(createElement("img", {
                        src: url_1,
                        className: join(" ", toList(delay(() => append(singleton("image"), delay(() => (minimize ? singleton("minimize") : empty())))))),
                        onClick: (e_5) => {
                            e_5.preventDefault();
                            patternInput[1](!minimize);
                        },
                    })), createElement("picture", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                    })))))), delay(() => singleton(createElement("br", {}))));
                }))));
            }))));
        }));
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
    }))), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elm = [(elms_4 = toList(delay(() => {
        let elms_3, children_17;
        return append(singleton((elms_3 = singleton_1((children_17 = ofArray([createElement("a", {
            children: "reply",
            onClick: (e_6) => {
                e_6.preventDefault();
                dispatch(new Msg(11, comment));
            },
        }), " . ", `${formatDistance(comment.CreatedAt)}`]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_17)),
        }))), createElement("div", {
            className: "level-item",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))), delay(() => {
            let comment_1, children_4, children_2, children, value_1, elems_2, elems, elems_1;
            const matchValue_6 = state.CurrentUser;
            return (matchValue_6.tag === 0) ? singleton(null) : (User__InRole_7B3101B3(matchValue_6.fields[0], new UserModule_Role(1)) ? singleton((comment_1 = comment, (children_4 = ofArray([(children_2 = singleton_1((children = singleton_1((value_1 = defaultArg(comment_1.IPAddress, ""), createElement("b", {
                children: [value_1],
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), createElement("div", createObj(ofArray([["className", "row"], (elems_2 = [createElement("a", createObj(ofArray([["className", "has-text-danger"], (elems = [createElement("i", {
                className: join(" ", ["fa-solid", "fa-ban"]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]), ["onClick", (e) => {
                e.preventDefault();
                const matchValue = comment_1.IPAddress;
                if (matchValue == null) {
                }
                else {
                    dispatch(new Msg(15, new AsyncOperationStatusWith$2(0, [matchValue, void 0])));
                }
            }]]))), createElement("a", createObj(ofArray([["className", "has-text-danger"], (elems_1 = [createElement("i", {
                className: join(" ", ["fa-solid", "fa-trash"]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["onClick", (e_1) => {
                e_1.preventDefault();
                dispatch(new Msg(10, new AsyncOperationStatusWith$2(0, comment_1.Id)));
            }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })))) : singleton(null));
        }));
    })), createElement("div", {
        className: "level-right",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))], createElement("nav", {
        className: "level",
        children: Interop_reactApi.Children.toArray(Array.from(elm)),
    }))]), createElement("div", {
        className: "media-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })));
    return createElement("article", {
        className: "media",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    });
}

export function renderComments(state, dispatch) {
    const matchValue = state.Comments;
    switch (matchValue.tag) {
        case 1: {
            return empty_1();
        }
        case 2: {
            return map((comment) => createElement(RenderComment, {
                comment: comment,
                state: state,
                dispatch: dispatch,
            }), matchValue.fields[0]);
        }
        default: {
            return empty_1();
        }
    }
}

export function moreButton(state, dispatch) {
    const children = ofArray([createElement("br", {}), createElement("a", {
        children: "more",
        onClick: (e) => {
            e.preventDefault();
            dispatch(new Msg(2, new AsyncOperationStatus$1(0)));
        },
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function spinner(state) {
    const props = toList(delay(() => append(singleton(["className", "is-link"]), delay(() => append(singleton(["className", "is-light"]), delay(() => (state.Networking ? singleton(["className", "is-active"]) : empty())))))));
    return createElement("div", createObj(Helpers_combineClasses("pageloader", props)));
}

export function progress(state, dispatch) {
    const props_5 = toList(delay(() => append(singleton(["id", "modal-posting"]), delay(() => append(state.Posting ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_1, elms;
        return singleton((elems_1 = [createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms = singleton_1(createElement("progress", createObj(Helpers_combineClasses("progress", ofArray([["className", "is-primary"], ["max", 100]]))))), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    }))))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_5)));
}

export function replyingTo(state, dispatch) {
    const matchValue = state.ReplyTo;
    if (matchValue == null) {
        return null;
    }
    else {
        const comment = matchValue;
        const children = ofArray([createElement("br", {}), createElement("p", {
            children: `Replying to Comment #${comment.Id}`,
        }), createElement("a", {
            children: "Remove",
            onClick: (e) => {
                e.preventDefault();
                dispatch(new Msg(11, void 0));
            },
        })]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

export function title(state, dispatch) {
    let elems, children_4, children, href, children_2, href_1;
    const matchValue = state.Comments;
    if (matchValue.tag === 2) {
        const comments = matchValue.fields[0];
        if (length(comments) > 0) {
            const props_5 = ofArray([["aria-label", "breadcrumbs"], (elems = [(children_4 = ofArray([(children = singleton_1(createElement("a", {
                children: `${item(0, comments).Board.Name}`,
                onClick: (href = boardHRef(toString(item(0, comments).Board.Id)), (e) => {
                    goToUrl(dispatch, href, e);
                }),
            })), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = singleton_1(createElement("a", {
                children: `${item(0, comments).PostSubject}`,
                className: "is-active",
                onClick: (href_1 = postHRef(toString(item(0, comments).Board.Id), toString(item(0, comments).PostId)), (e_1) => {
                    goToUrl(dispatch, href_1, e_1);
                }),
            })), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
            return createElement("nav", createObj(Helpers_combineClasses("breadcrumb", props_5)));
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}

export function editor(state, dispatch) {
    const props = toList(delay(() => {
        let matchValue;
        return append((matchValue = state.CurrentUser, (matchValue.tag === 1) ? singleton(["username", matchValue.fields[0].Username]) : append(singleton(["useUsernameInput", true]), delay(() => append(singleton(["username", matchValue.fields[0].Username]), delay(() => append(singleton(["useCaptcha", true]), delay(() => singleton(["onCaptchaTokenChanged", (arg) => {
            dispatch(new Msg(6, arg));
        }])))))))), delay(() => append(singleton(["onUsernameChanged", (arg_1) => {
            dispatch(new Msg(3, arg_1));
        }]), delay(() => append(singleton(["body", state.Body]), delay(() => append(singleton(["onBodyChanged", (arg_2) => {
            dispatch(new Msg(4, arg_2));
        }]), delay(() => append(singleton(["file", state.File]), delay(() => append(singleton(["onFileChanged", (arg_3) => {
            dispatch(new Msg(5, arg_3));
        }]), delay(() => append(singleton(["show", true]), delay(() => singleton(["onSubmit", () => {
            dispatch(new Msg(7, new AsyncOperationStatus$1(0)));
        }])))))))))))))));
    }));
    return createElement(ComposeModule_compose, {
        input: createObj(props),
    });
}

export function render(state, dispatch) {
    let children, list2;
    const elms = ofArray([userPanel(state, dispatch), title(state, dispatch), (children = ((list2 = ofArray([moreButton(state, dispatch), replyingTo(state, dispatch), editor(state, dispatch), progress(state, dispatch), spinner(state)]), append_1(renderComments(state, dispatch), list2))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

