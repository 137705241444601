import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { updateCssVar, errorToast, successToast, SecureRequest$1, UpdatePasswordRequest, AsyncOperationStatus$1, Cmd_fromAsync, IBoardApi$reflection, Route_builder } from "../../../Shared.fs.js";
import { Msg, State } from "./Types.fs.js";
import { Cmd_batch, Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { setLinkColor, setTextColor, setBackgroundColor, getToken } from "../../../LocalStore.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { isNullOrWhiteSpace } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { Cmd_OfFunc_result } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function update(msg, state) {
    let pattern_matching_result, password, password_1, email;
    if (msg.tag === 2) {
        pattern_matching_result = 0;
        password = msg.fields[0];
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 1;
        password_1 = msg.fields[0];
    }
    else if (msg.tag === 0) {
        pattern_matching_result = 2;
        email = msg.fields[0];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 0) {
            if (state.UpdatingPassword) {
                pattern_matching_result = 3;
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, password, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_none()];
        }
        case 1: {
            return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, password_1, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_none()];
        }
        case 2: {
            return [new State(state.CurrentUser, email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_none()];
        }
        case 3: {
            return [state, Cmd_none()];
        }
        case 4: {
            let pattern_matching_result_1, err;
            if (msg.tag === 4) {
                if (msg.fields[0].tag === 1) {
                    if (msg.fields[0].fields[0].tag === 1) {
                        pattern_matching_result_1 = 2;
                        err = msg.fields[0].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (msg.tag === 1) {
                if (msg.fields[0].tag === 0) {
                    if (state.UpdatingEmail) {
                        pattern_matching_result_1 = 3;
                    }
                    else {
                        pattern_matching_result_1 = 4;
                    }
                }
                else {
                    pattern_matching_result_1 = 4;
                }
            }
            else {
                pattern_matching_result_1 = 4;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, true, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_fromAsync(singleton.Delay(() => {
                        const matchValue = getToken();
                        if (matchValue == null) {
                            return singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "invalid token"))));
                        }
                        else {
                            const request = new SecureRequest$1(matchValue, new UpdatePasswordRequest(state.CurrentPassword, state.NewPassword));
                            return singleton.Bind(boardApi.UpdatePassword(request), (_arg1) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, _arg1))));
                        }
                    }))];
                }
                case 1: {
                    return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, "", "", false, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), successToast("Updated Password")];
                }
                case 2: {
                    return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, false, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), errorToast(err)];
                }
                case 3: {
                    return [state, Cmd_none()];
                }
                case 4: {
                    let pattern_matching_result_2, err_1;
                    if (msg.tag === 1) {
                        if (msg.fields[0].tag === 1) {
                            if (msg.fields[0].fields[0].tag === 1) {
                                pattern_matching_result_2 = 2;
                                err_1 = msg.fields[0].fields[0].fields[0];
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 0;
                        }
                    }
                    else if (msg.tag === 5) {
                        if (msg.fields[0].tag === 0) {
                            if (state.Networking) {
                                pattern_matching_result_2 = 3;
                            }
                            else {
                                pattern_matching_result_2 = 4;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 4;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 4;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return [new State(state.CurrentUser, state.Email, true, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_fromAsync(singleton.Delay(() => {
                                const matchValue_1 = getToken();
                                if (matchValue_1 == null) {
                                    return singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "invalid tokrn"))));
                                }
                                else {
                                    const request_1 = new SecureRequest$1(matchValue_1, isNullOrWhiteSpace(state.Email) ? (void 0) : state.Email);
                                    return singleton.Bind(boardApi.UpdateEmail(request_1), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, _arg2))));
                                }
                            }))];
                        }
                        case 1: {
                            return [new State(state.CurrentUser, state.Email, false, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), successToast("Updated Email Address")];
                        }
                        case 2: {
                            return [new State(state.CurrentUser, state.Email, false, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), errorToast(err_1)];
                        }
                        case 3: {
                            return [state, Cmd_none()];
                        }
                        case 4: {
                            if (msg.tag === 5) {
                                if (msg.fields[0].tag === 1) {
                                    return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, false, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_none()];
                                }
                                else {
                                    return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, true, state.Menu, state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_fromAsync(singleton.Delay(() => {
                                        const matchValue_2 = getToken();
                                        if (matchValue_2 == null) {
                                            return singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, void 0)));
                                        }
                                        else {
                                            const request_2 = new SecureRequest$1(matchValue_2, state.Email);
                                            return singleton.Bind(boardApi.SendVerificationEmail(request_2), () => singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, void 0))));
                                        }
                                    }))];
                                }
                            }
                            else if (msg.tag === 6) {
                                return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, msg.fields[0], state.BackgroundColor, state.TextColor, state.LinkColor), Cmd_none()];
                            }
                            else if (msg.tag === 7) {
                                updateCssVar("--background-color", msg.fields[0]);
                                setBackgroundColor(msg.fields[0]);
                                return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, msg.fields[0], state.TextColor, state.LinkColor), Cmd_none()];
                            }
                            else if (msg.tag === 8) {
                                updateCssVar("--text-color", msg.fields[0]);
                                setTextColor(msg.fields[0]);
                                return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, msg.fields[0], state.LinkColor), Cmd_none()];
                            }
                            else if (msg.tag === 9) {
                                updateCssVar("--link-color", msg.fields[0]);
                                setLinkColor(msg.fields[0]);
                                return [new State(state.CurrentUser, state.Email, state.UpdatingEmail, state.CurrentPassword, state.NewPassword, state.UpdatingPassword, state.Networking, state.Menu, state.BackgroundColor, state.TextColor, msg.fields[0]), Cmd_none()];
                            }
                            else if (msg.tag === 10) {
                                return [state, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(7, "#FFFFFF")), Cmd_OfFunc_result(new Msg(8, "#000000")), Cmd_OfFunc_result(new Msg(9, "#0000FF"))]))];
                            }
                            else {
                                throw (new Error("Match failure: Profile.Types.Msg"));
                            }
                        }
                    }
                }
            }
        }
    }
}

