import { Union, Record } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { union_type, record_type, bool_type, option_type, string_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { CaptchaToken, AsyncOperationStatus$1$reflection, SignupError$reflection, User$reflection, CaptchaToken$reflection } from "../../../Shared.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class State extends Record {
    constructor(Username, Email, Password, PasswordRepeat, Token, Networking) {
        super();
        this.Username = Username;
        this.Email = Email;
        this.Password = Password;
        this.PasswordRepeat = PasswordRepeat;
        this.Token = Token;
        this.Networking = Networking;
    }
}

export function State$reflection() {
    return record_type("Signup.Types.State", [], State, () => [["Username", string_type], ["Email", option_type(string_type)], ["Password", string_type], ["PasswordRepeat", string_type], ["Token", CaptchaToken$reflection()], ["Networking", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "SetEmail", "SetPassword", "SetPasswordRepeat", "SetToken", "Signup"];
    }
}

export function Msg$reflection() {
    return union_type("Signup.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", CaptchaToken$reflection()]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [User$reflection(), SignupError$reflection()], FSharpResult$2, () => [[["ResultValue", User$reflection()]], [["ErrorValue", SignupError$reflection()]]]))]]]);
}

export function init() {
    return [new State("", void 0, "", "", new CaptchaToken(0, ""), false), Cmd_none()];
}

