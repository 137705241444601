import { formatDistance } from "date-fns";
import { now } from "../../../../Client/fable_modules/fable-library.3.7.5/DateOffset.js";
import { createElement } from "react";
import { cons, ofArray, singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { equals, createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { Settings, Msg } from "./Types.fs.js";
import { isNullOrEmpty } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { AsyncOperationStatus$1, Validation_isValidEmail } from "../../../Shared.fs.js";

export function createdDistance(state) {
    return `created ${formatDistance(state.CurrentUser.CreatedAt, now(), {})} ago`;
}

export function account(state, dispatch) {
    let elms_2, elms_1, elms, elms_5, elms_10, elms_7, elms_6, elms_9, elms_8, props_30;
    const verified = state.CurrentUser.EmailVerified ? "verified" : "unverified";
    const children_11 = ofArray([(elms_2 = singleton((elms_1 = ofArray([createElement("label", {
        children: "Username",
    }), (elms = singleton(createElement("strong", {
        children: state.CurrentUser.Username,
    })), createElement("p", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("div", {
        children: createdDistance(state),
    })]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), createElement("br", {}), (elms_5 = toList(delay(() => {
        let elms_4, elms_3;
        return append(singleton_1((elms_4 = ofArray([createElement("label", {
            children: `Email (${verified})`,
        }), (elms_3 = singleton(createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["value", state.Email], ["placeholder", "Email"], ["onChange", (ev) => {
            dispatch(new Msg(0, ev.target.value));
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))), delay(() => {
            let props_12;
            return append(singleton_1((props_12 = toList(delay(() => append(state.UpdatingEmail ? append(singleton_1(["className", "is-loading"]), delay(() => singleton_1(["disabled", true]))) : ((!isNullOrEmpty(state.Email)) ? singleton_1(["disabled", !Validation_isValidEmail(state.Email)]) : empty()), delay(() => append(singleton_1(["onClick", (e) => {
                e.preventDefault();
                dispatch(new Msg(1, new AsyncOperationStatus$1(0)));
            }]), delay(() => singleton_1(["children", "Update Email"]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), delay(() => append(singleton_1(createElement("br", {})), delay(() => append(singleton_1(createElement("br", {})), delay(() => {
                let props_16;
                return ((!state.CurrentUser.EmailVerified) && Validation_isValidEmail(state.Email)) ? singleton_1((props_16 = toList(delay(() => (state.Networking ? append(singleton_1(["className", "is-loading"]), delay(() => singleton_1(["disabled", true]))) : append(singleton_1(["children", "Resend Verification Email"]), delay(() => singleton_1(["onClick", (e_1) => {
                    e_1.preventDefault();
                    dispatch(new Msg(1, new AsyncOperationStatus$1(0)));
                }])))))), createElement("button", createObj(Helpers_combineClasses("button", props_16))))) : singleton_1(null);
            }))))));
        }));
    })), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), createElement("br", {}), (elms_10 = ofArray([(elms_7 = ofArray([createElement("label", {
        children: "Current Password",
    }), (elms_6 = singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["value", state.CurrentPassword], ["placeholder", "*******"], ["onChange", (ev_1) => {
        dispatch(new Msg(2, ev_1.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })), (elms_9 = ofArray([createElement("label", {
        children: "New Password",
    }), (elms_8 = singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["value", state.NewPassword], ["placeholder", "*******"], ["onChange", (ev_2) => {
        dispatch(new Msg(3, ev_2.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    })), (props_30 = toList(delay(() => append(state.UpdatingPassword ? append(singleton_1(["className", "is-loading"]), delay(() => singleton_1(["disabled", true]))) : empty(), delay(() => append(singleton_1(["onClick", (e_2) => {
        e_2.preventDefault();
        dispatch(new Msg(4, new AsyncOperationStatus$1(0)));
    }]), delay(() => singleton_1(["children", "Update Password"]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_30))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    }))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    });
}

export function appearance(state, dispatch) {
    let elms_1, elms, elms_3, elms_2, elms_5, elms_4, elms_7, elms_6;
    const elms_8 = ofArray([(elms_1 = ofArray([createElement("label", {
        className: "custom-color-desc",
        children: "Background Color",
    }), (elms = singleton(createElement("input", createObj(cons(["type", "color"], Helpers_combineClasses("input", ofArray([["value", state.BackgroundColor], ["onChange", (ev) => {
        dispatch(new Msg(7, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = ofArray([createElement("label", {
        className: "custom-color-desc",
        children: "Text Color",
    }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "color"], Helpers_combineClasses("input", ofArray([["value", state.TextColor], ["onChange", (ev_1) => {
        dispatch(new Msg(8, ev_1.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_5 = ofArray([createElement("label", {
        className: "custom-color-desc",
        children: "Link Color",
    }), (elms_4 = singleton(createElement("input", createObj(cons(["type", "color"], Helpers_combineClasses("input", ofArray([["value", state.LinkColor], ["onChange", (ev_2) => {
        dispatch(new Msg(9, ev_2.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_7 = singleton((elms_6 = singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Set to Default"], ["onClick", (_arg1) => {
        dispatch(new Msg(10));
    }]]))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))]);
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    });
}

export function render(state, dispatch) {
    let elms_3, elms_2, elms_1, elms, props_1, children, props_4, children_2, props_10, elems_3;
    const children_8 = singleton((elms_3 = ofArray([(elms_2 = singleton((elms_1 = ofArray([createElement("p", {
        className: "menu-label",
        children: "Profile",
    }), (elms = ofArray([(props_1 = toList(delay(() => append(equals(state.Menu, new Settings(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => append(singleton_1(["children", "Account"]), delay(() => singleton_1(["onClick", (_arg1) => {
        dispatch(new Msg(6, new Settings(0)));
    }]))))))), (children = singleton(createElement("a", createObj(Helpers_combineClasses("", props_1)))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), (props_4 = toList(delay(() => append(equals(state.Menu, new Settings(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => append(singleton_1(["children", "Appearance"]), delay(() => singleton_1(["onClick", (_arg2) => {
        dispatch(new Msg(6, new Settings(1)));
    }]))))))), (children_2 = singleton(createElement("a", createObj(Helpers_combineClasses("", props_4)))), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })))]), createElement("ul", {
        className: "menu-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("aside", {
        className: "menu",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (props_10 = ofArray([["className", "is-three-quarters"], (elems_3 = toList(delay(() => ((state.Menu.tag === 1) ? singleton_1(appearance(state, dispatch)) : singleton_1(account(state, dispatch))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    });
}

