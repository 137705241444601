import { Record, Union } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { unit_type, record_type, bool_type, string_type, union_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { AsyncOperationStatus$1$reflection, User$reflection } from "../../../Shared.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { defaultArg } from "../../../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { getLinkColor, getTextColor, getBackgroundColor } from "../../../LocalStore.fs.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class Settings extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Account", "Appearance"];
    }
}

export function Settings$reflection() {
    return union_type("Profile.Types.Settings", [], Settings, () => [[], []]);
}

export class State extends Record {
    constructor(CurrentUser, Email, UpdatingEmail, CurrentPassword, NewPassword, UpdatingPassword, Networking, Menu, BackgroundColor, TextColor, LinkColor) {
        super();
        this.CurrentUser = CurrentUser;
        this.Email = Email;
        this.UpdatingEmail = UpdatingEmail;
        this.CurrentPassword = CurrentPassword;
        this.NewPassword = NewPassword;
        this.UpdatingPassword = UpdatingPassword;
        this.Networking = Networking;
        this.Menu = Menu;
        this.BackgroundColor = BackgroundColor;
        this.TextColor = TextColor;
        this.LinkColor = LinkColor;
    }
}

export function State$reflection() {
    return record_type("Profile.Types.State", [], State, () => [["CurrentUser", User$reflection()], ["Email", string_type], ["UpdatingEmail", bool_type], ["CurrentPassword", string_type], ["NewPassword", string_type], ["UpdatingPassword", bool_type], ["Networking", bool_type], ["Menu", Settings$reflection()], ["BackgroundColor", string_type], ["TextColor", string_type], ["LinkColor", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetEmail", "UpdateEmail", "SetCurrentPassword", "SetNewPassword", "UpdatePassword", "ResendEmail", "ChangeMenu", "SetBackgroundColor", "SetTextColor", "SetLinkColor", "SetToDefaultColors"];
    }
}

export function Msg$reflection() {
    return union_type("Profile.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(unit_type)]], [["Item", Settings$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], []]);
}

export function init(currentUser) {
    return [new State(currentUser, defaultArg(currentUser.Email, ""), false, "", "", false, false, new Settings(0), getBackgroundColor(), getTextColor(), getLinkColor()), Cmd_none()];
}

