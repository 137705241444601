import { RouterModule_router, RouterModule_encodeParts } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { map, empty as empty_1, singleton as singleton_1, tail, head, isEmpty, ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Url, Msg } from "./Types.fs.js";
import { Route_$007CInt64$007C_$007C } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { createElement } from "react";
import { AsyncOperationStatusWith$2, UserModule_Role, User__InRole_7B3101B3, AsyncOperationStatus$1, formatDistance } from "../../../Shared.fs.js";
import { createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { printf, toText, endsWith, join } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { empty, append, singleton, delay, toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { ComposeModule_compose } from "../../../Components/./Compose.fs.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { toString } from "../../../../Client/fable_modules/fable-library.3.7.5/Long.js";
import { defaultArg } from "../../../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { render as render_1 } from "./Comments/View.fs.js";

export function commentHref(boardId, postId) {
    return RouterModule_encodeParts(ofArray(["boards", boardId, "posts", postId]), 2);
}

export function goToUrl(dispatch, href, e) {
    e.preventDefault();
    dispatch(new Msg(1, href));
}

export function parseUrl(_arg1) {
    let pattern_matching_result, boardId;
    if (!isEmpty(_arg1)) {
        const activePatternResult38664 = Route_$007CInt64$007C_$007C(head(_arg1));
        if (activePatternResult38664 != null) {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 0;
                boardId = activePatternResult38664;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Url(0, boardId);
        }
        case 1: {
            let pattern_matching_result_1, postId;
            if (!isEmpty(_arg1)) {
                if (Route_$007CInt64$007C_$007C(head(_arg1)) != null) {
                    if (!isEmpty(tail(_arg1))) {
                        if (head(tail(_arg1)) === "posts") {
                            if (!isEmpty(tail(tail(_arg1)))) {
                                const activePatternResult38663 = Route_$007CInt64$007C_$007C(head(tail(tail(_arg1))));
                                if (activePatternResult38663 != null) {
                                    if (isEmpty(tail(tail(tail(_arg1))))) {
                                        pattern_matching_result_1 = 0;
                                        postId = activePatternResult38663;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new Url(1, postId, void 0);
                }
                case 1: {
                    let pattern_matching_result_2, commentId, postId_1;
                    if (!isEmpty(_arg1)) {
                        if (Route_$007CInt64$007C_$007C(head(_arg1)) != null) {
                            if (!isEmpty(tail(_arg1))) {
                                if (head(tail(_arg1)) === "posts") {
                                    if (!isEmpty(tail(tail(_arg1)))) {
                                        const activePatternResult38660 = Route_$007CInt64$007C_$007C(head(tail(tail(_arg1))));
                                        if (activePatternResult38660 != null) {
                                            if (!isEmpty(tail(tail(tail(_arg1))))) {
                                                if (head(tail(tail(tail(_arg1)))) === "comments") {
                                                    if (!isEmpty(tail(tail(tail(tail(_arg1)))))) {
                                                        const activePatternResult38661 = Route_$007CInt64$007C_$007C(head(tail(tail(tail(tail(_arg1))))));
                                                        if (activePatternResult38661 != null) {
                                                            if (isEmpty(tail(tail(tail(tail(tail(_arg1))))))) {
                                                                pattern_matching_result_2 = 0;
                                                                commentId = activePatternResult38661;
                                                                postId_1 = activePatternResult38660;
                                                            }
                                                            else {
                                                                pattern_matching_result_2 = 1;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_2 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_2 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_2 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_2 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_2 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return new Url(1, postId_1, commentId);
                        }
                        case 1: {
                            return new Url(2);
                        }
                    }
                }
            }
        }
    }
}

export function lastUpdatedView(post) {
    return createElement("small", {
        children: `last updated ${formatDistance(post.UpdatedAt)}`,
    });
}

export function index(state, dispatch) {
    let elems, elems_1, elems_15, state_2, props_15, properties_27, elems_13, elms_2, elems_3;
    let editor;
    const createPost = createElement("h1", createObj(ofArray([["id", "show-create-post"], ["className", join(" ", ["has-text-centered"])], (elems = [createElement("a", {
        children: "[create a new post]",
        onClick: (_arg1) => {
            dispatch(new Msg(16, true));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    const elms = singleton_1(createElement("div", createObj(ofArray([["id", "editor-container"], (elems_1 = toList(delay(() => ((!state.ShowCreatePost) ? singleton(createPost) : append(singleton(null), delay(() => {
        let props_2;
        return singleton((props_2 = toList(delay(() => {
            let matchValue;
            return append((matchValue = state.CurrentUser, (matchValue.tag === 1) ? singleton(["username", matchValue.fields[0].Username]) : append(singleton(["useUsernameInput", true]), delay(() => append(singleton(["username", matchValue.fields[0].Username]), delay(() => append(singleton(["useCaptcha", true]), delay(() => singleton(["onCaptchaTokenChanged", (arg) => {
                dispatch(new Msg(9, arg));
            }])))))))), delay(() => append(singleton(["onUsernameChanged", (arg_1) => {
                dispatch(new Msg(5, arg_1));
            }]), delay(() => append(singleton(["useSubject", true]), delay(() => append(singleton(["subject", state.Subject]), delay(() => append(singleton(["onSubjectChanged", (arg_2) => {
                dispatch(new Msg(6, arg_2));
            }]), delay(() => append(singleton(["body", state.Body]), delay(() => append(singleton(["onBodyChanged", (arg_3) => {
                dispatch(new Msg(7, arg_3));
            }]), delay(() => append(singleton(["file", state.File]), delay(() => append(singleton(["onFileChanged", (arg_4) => {
                dispatch(new Msg(8, arg_4));
            }]), delay(() => append(singleton(["show", state.ShowCreatePost]), delay(() => singleton(["onSubmit", () => {
                dispatch(new Msg(10, new AsyncOperationStatus$1(0)));
            }])))))))))))))))))))));
        })), createElement(ComposeModule_compose, {
            input: createObj(props_2),
        })));
    }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
    editor = createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
    let spinner;
    const props_5 = toList(delay(() => append(singleton(["className", "is-link"]), delay(() => append(singleton(["className", "is-light"]), delay(() => (state.Networking ? singleton(["className", "is-active"]) : empty())))))));
    spinner = createElement("div", createObj(Helpers_combineClasses("pageloader", props_5)));
    const dispatch_4 = dispatch;
    const state_4 = state;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_15 = [editor, (state_2 = state_4, (props_15 = toList(delay(() => append(singleton(["id", "modal-posting"]), delay(() => append(state_2.Posting ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_5, elms_1;
        return singleton((elems_5 = [createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms_1 = toList(delay(() => (state_2.Posting ? singleton(createElement("progress", createObj(Helpers_combineClasses("progress", ofArray([["className", "is-primary"], ["max", 100]]))))) : empty()))), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    })))))), createElement("div", createObj(Helpers_combineClasses("modal", props_15))))), createElement("div", createObj(ofArray([["className", join(" ", ["catalog"])], (properties_27 = singleton_1(["marginTop", 16 + "px"]), ["style", createObj(properties_27)]), (elems_13 = toList(delay(() => {
        const matchValue_5 = state_4.Posts;
        switch (matchValue_5.tag) {
            case 1: {
                return singleton(spinner);
            }
            case 2: {
                return map((post_2) => {
                    let elems_11, elems_10, elems_12;
                    const dispatch_3 = dispatch_4;
                    const state_3 = state_4;
                    const post = post_2;
                    let getPostFileUrl;
                    const noImageFound = "No image found.";
                    const matchValue_2 = post.Comment;
                    if (matchValue_2 == null) {
                        getPostFileUrl = noImageFound;
                    }
                    else {
                        const matchValue_3 = matchValue_2.FileUrl;
                        getPostFileUrl = ((matchValue_3 == null) ? noImageFound : matchValue_3);
                    }
                    const linkHref = commentHref(toString(state_3.BoardId), toString(post.Id));
                    const postMedia = createElement("div", createObj(singleton_1((elems_11 = [createElement("a", createObj(ofArray([["href", linkHref], ["onClick", (e_3) => {
                        goToUrl(dispatch_3, linkHref, e_3);
                    }], (elems_10 = toList(delay(() => {
                        let properties_17, elems_9, children_8, properties_20;
                        return endsWith(getPostFileUrl.toLocaleLowerCase(), ".webm") ? singleton(createElement("video", createObj(ofArray([["controls", true], ["alt", toText(printf("%s - post image"))(post.Subject)], (properties_17 = ofArray([["minWidth", 100 + "px"], ["minHeight", 100 + "px"], ["maxWidth", 150 + "px"], ["maxHeight", 150 + "px"]]), ["style", createObj(properties_17)]), (elems_9 = [createElement("source", {
                            src: getPostFileUrl,
                            type: "video/webm",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))) : singleton((children_8 = singleton_1(createElement("img", createObj(ofArray([["alt", toText(printf("%s - post image"))(post.Subject)], ["className", "image"], (properties_20 = ofArray([["minWidth", 100 + "px"], ["minHeight", 100 + "px"], ["maxWidth", 150 + "px"], ["maxHeight", 150 + "px"]]), ["style", createObj(properties_20)]), ["src", getPostFileUrl]])))), createElement("picture", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                        })));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))]))));
                    const postSubject = createElement("a", {
                        href: linkHref,
                        onClick: (e_4) => {
                            goToUrl(dispatch_3, linkHref, e_4);
                        },
                        children: post.Subject,
                    });
                    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_12 = toList(delay(() => append(singleton(postMedia), delay(() => append(singleton(postSubject), delay(() => append(singleton(createElement("br", {})), delay(() => append(singleton(lastUpdatedView(post)), delay(() => {
                        let post_1, children_6, children_4, children_2, value_45, elems_8, elems_6, elems_7;
                        const matchValue_4 = state_3.CurrentUser;
                        return (matchValue_4.tag === 0) ? singleton(null) : (User__InRole_7B3101B3(matchValue_4.fields[0], new UserModule_Role(1)) ? singleton((post_1 = post, (children_6 = ofArray([(children_4 = singleton_1((children_2 = singleton_1((value_45 = defaultArg(post_1.IPAddress, ""), createElement("b", {
                            children: [value_45],
                        }))), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                        }))), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                        })), createElement("div", createObj(ofArray([["className", "row"], (elems_8 = [createElement("a", createObj(ofArray([["className", "has-text-danger"], (elems_6 = [createElement("i", {
                            className: join(" ", ["fa-solid", "fa-ban"]),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]), ["onClick", (e_1) => {
                            e_1.preventDefault();
                            const matchValue_1 = post_1.IPAddress;
                            if (matchValue_1 == null) {
                            }
                            else {
                                dispatch_3(new Msg(14, new AsyncOperationStatusWith$2(0, [matchValue_1, void 0])));
                            }
                        }]]))), createElement("a", createObj(ofArray([["className", "has-text-danger"], (elems_7 = [createElement("i", {
                            className: join(" ", ["fa-solid", "fa-trash"]),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]), ["onClick", (e_2) => {
                            e_2.preventDefault();
                            dispatch_3(new Msg(13, new AsyncOperationStatusWith$2(0, post_1.Id)));
                        }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                        })))) : singleton(null));
                    })))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])));
                }, matchValue_5.fields[0]);
            }
            default: {
                return singleton(null);
            }
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), (elms_2 = singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["has-text-centered"])], (elems_3 = [createElement("br", {}), createElement("a", {
        children: "[refresh]",
        onClick: (e) => {
            e.preventDefault();
            dispatch_4(new Msg(3, new AsyncOperationStatus$1(0)));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])));
}

export function render(state, dispatch) {
    let activePage;
    const matchValue = state.CurrentPage;
    switch (matchValue.tag) {
        case 1: {
            activePage = render_1(matchValue.fields[0], (arg) => {
                dispatch(new Msg(2, arg));
            });
            break;
        }
        case 2: {
            activePage = createElement("h1", {
                children: ["Not Found"],
            });
            break;
        }
        default: {
            activePage = index(state, dispatch);
        }
    }
    return RouterModule_router({
        hashMode: 2,
        onUrlChanged: (arg_2) => {
            dispatch(new Msg(0, parseUrl(arg_2)));
        },
        application: activePage,
    });
}

