import { Record } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { record_type, string_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class State extends Record {
    constructor(Message) {
        super();
        this.Message = Message;
    }
}

export function State$reflection() {
    return record_type("EmailVerified.Types.State", [], State, () => [["Message", string_type]]);
}

export function init(message) {
    return [new State(message), Cmd_none()];
}

