import { Record, Union } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { AsyncOperationStatus$1, Deferred$1, Deferred$1$reflection, AsyncOperationStatusWith$2$reflection, AsyncOperationStatus$1$reflection, NetworkError$reflection, User$reflection } from "../../../Shared.fs.js";
import { record_type, option_type, bool_type, unit_type, tuple_type, class_type, string_type, union_type, list_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { addDays, now } from "../../../../Client/fable_modules/fable-library.3.7.5/Date.js";
import { Cmd_OfFunc_result } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetUsers", "SetSearch", "AssignUserRole", "RemoveUserRole", "ToggleUserMenu", "SelectUser"];
    }
}

export function Msg$reflection() {
    return union_type("Admin.Types.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(User$reflection()), NetworkError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(User$reflection())]], [["ErrorValue", NetworkError$reflection()]]]))]], [["Item", string_type]], [["Item", AsyncOperationStatusWith$2$reflection(tuple_type(class_type("System.Int64"), string_type), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, NetworkError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", NetworkError$reflection()]]]))]], [["Item", AsyncOperationStatusWith$2$reflection(tuple_type(class_type("System.Int64"), string_type), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, NetworkError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", NetworkError$reflection()]]]))]], [["Item", bool_type]], [["Item", option_type(User$reflection())]]]);
}

export class State extends Record {
    constructor(CurrentUser, Users, Search, Networking, ShowUserMenu, SelectedUser, BanDate, IndefiniteBan) {
        super();
        this.CurrentUser = CurrentUser;
        this.Users = Users;
        this.Search = Search;
        this.Networking = Networking;
        this.ShowUserMenu = ShowUserMenu;
        this.SelectedUser = SelectedUser;
        this.BanDate = BanDate;
        this.IndefiniteBan = IndefiniteBan;
    }
}

export function State$reflection() {
    return record_type("Admin.Types.State", [], State, () => [["CurrentUser", User$reflection()], ["Users", Deferred$1$reflection(list_type(User$reflection()))], ["Search", string_type], ["Networking", bool_type], ["ShowUserMenu", bool_type], ["SelectedUser", option_type(User$reflection())], ["BanDate", class_type("System.DateTime")], ["IndefiniteBan", bool_type]]);
}

export function init(currentUser) {
    let copyOfStruct;
    return [new State(currentUser, new Deferred$1(0), "", false, false, void 0, (copyOfStruct = now(), addDays(copyOfStruct, 1)), false), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

