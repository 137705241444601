import { AsyncOperationStatus$1, Validation_isValidEmail } from "../../../Shared.fs.js";
import { createElement } from "react";
import { createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { Msg } from "./Types.fs.js";
import { singleton, cons, ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";

export function render(state, dispatch) {
    let elms_3, elms_1, elms, elms_2, props_5;
    const valid = Validation_isValidEmail(state.email);
    const children_4 = singleton((elms_3 = ofArray([(elms_1 = ofArray([createElement("label", {
        children: "Email",
    }), (elms = singleton(createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["value", state.email], ["placeholder", "Email"], ["onChange", (ev) => {
        dispatch(new Msg(0, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = singleton((props_5 = toList(delay(() => (state.networking ? append(singleton_1(["className", "is-loading"]), delay(() => append(singleton_1(["className", "is-link"]), delay(() => append(singleton_1(["disabled", !valid]), delay(() => append(singleton_1(["children", "Send Password Reset Link"]), delay(() => singleton_1(["onClick", (e) => {
        e.preventDefault();
        dispatch(new Msg(1, new AsyncOperationStatus$1(0)));
    }]))))))))) : empty()))), createElement("button", createObj(Helpers_combineClasses("button", props_5))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

