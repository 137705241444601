import { Record } from "../Client/fable_modules/fable-library.3.7.5/Types.js";
import { record_type, int32_type, class_type, string_type } from "../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { fromBits } from "../Client/fable_modules/fable-library.3.7.5/Long.js";

export class Config extends Record {
    constructor(CaptchaSiteKey, PostsSize, CommentsSize, FileSizeLimit, CommentBodyLengthLimit, SubjectLengthLimit) {
        super();
        this.CaptchaSiteKey = CaptchaSiteKey;
        this.PostsSize = PostsSize;
        this.CommentsSize = CommentsSize;
        this.FileSizeLimit = (FileSizeLimit | 0);
        this.CommentBodyLengthLimit = (CommentBodyLengthLimit | 0);
        this.SubjectLengthLimit = (SubjectLengthLimit | 0);
    }
}

export function Config$reflection() {
    return record_type("Config.Config", [], Config, () => [["CaptchaSiteKey", string_type], ["PostsSize", class_type("System.Int64")], ["CommentsSize", class_type("System.Int64")], ["FileSizeLimit", int32_type], ["CommentBodyLengthLimit", int32_type], ["SubjectLengthLimit", int32_type]]);
}

export const config = new Config("d13a8d36-e59d-428c-883e-e6cdf4f121d3", fromBits(50, 0, false), fromBits(50, 0, false), 4194304, 700, 200);

