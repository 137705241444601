import { startAsPromise } from "./fable_modules/fable-library.3.7.5/Async.js";
import { get$ } from "./User.fs.js";
import { ProgramModule_mkProgram, ProgramModule_withSubscription, ProgramModule_run } from "./fable_modules/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactHydrate } from "./fable_modules/Fable.Elmish.React.3.0.1/react.fs.js";
import { update, subscription } from "../Shared/Pages/Home/State.fs.js";
import { init } from "../Shared/Pages/Home/Types.fs.js";
import { render } from "../Shared/Pages/Home/View.fs.js";

(function () {
    const pr = startAsPromise(get$());
    void (pr.then((user) => {
        ProgramModule_run(Program_withReactHydrate("elmish-app", ProgramModule_withSubscription(subscription, ProgramModule_mkProgram(() => init(user, void 0), update, render))));
    }));
})();

