import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "./fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { SecureRequest$1, GetUserRequest, AppUser, AnonymousUser_create, updateCssVar, IBoardApi$reflection, Route_builder } from "../Shared/Shared.fs.js";
import { singleton } from "./fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { removeToken, getToken, getLinkColor, getTextColor, getBackgroundColor } from "../Shared/LocalStore.fs.js";

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function get$() {
    return singleton.Delay(() => singleton.Combine((getBackgroundColor() !== "") ? ((updateCssVar("--background-color", getBackgroundColor()), singleton.Zero())) : singleton.Zero(), singleton.Delay(() => singleton.Combine((getTextColor() !== "") ? ((updateCssVar("--text-color", getTextColor()), singleton.Zero())) : singleton.Zero(), singleton.Delay(() => singleton.Combine((getLinkColor() !== "") ? ((updateCssVar("--link-color", getLinkColor()), singleton.Zero())) : singleton.Zero(), singleton.Delay(() => {
        const matchValue = getToken();
        if (matchValue == null) {
            return singleton.Return(new AppUser(0, AnonymousUser_create()));
        }
        else {
            const request = new SecureRequest$1(matchValue, new GetUserRequest(void 0));
            return singleton.Bind(boardApi.GetUser(request), (_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    removeToken();
                    return singleton.Return(new AppUser(0, AnonymousUser_create()));
                }
                else {
                    return singleton.Return(new AppUser(1, result.fields[0]));
                }
            });
        }
    })))))));
}

