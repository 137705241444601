import { Union, Record } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { union_type, unit_type, record_type, bool_type, string_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { AsyncOperationStatus$1$reflection, SendResetPasswordEmailError$reflection } from "../../../Shared.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class State extends Record {
    constructor(email, networking) {
        super();
        this.email = email;
        this.networking = networking;
    }
}

export function State$reflection() {
    return record_type("ForgotPassword.Types.State", [], State, () => [["email", string_type], ["networking", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetEmail", "SendEmail"];
    }
}

export function Msg$reflection() {
    return union_type("ForgotPassword.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, SendResetPasswordEmailError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", SendResetPasswordEmailError$reflection()]]]))]]]);
}

export function init() {
    return [new State("", false), Cmd_none()];
}

