import { RouterModule_encodeParts } from "../../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { cons, ofArray, singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Msg } from "./Types.fs.js";
import { AsyncOperationStatus$1, Validation_isValidUsername } from "../../../Shared.fs.js";
import { isNullOrEmpty } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { createElement } from "react";
import { createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";

export const signupHref = RouterModule_encodeParts(singleton("signup"), 2);

export const forgotHref = RouterModule_encodeParts(singleton("forgot-password"), 2);

export function goToUrl(dispatch, href, e) {
    e.preventDefault();
    dispatch(new Msg(3, href));
}

export function render(state, dispatch) {
    let children_5, elms_1, elms, elms_3, elms_2, elms_4, props_11;
    const inputValid = Validation_isValidUsername(state.Username) && (!isNullOrEmpty(state.Password));
    const children_7 = singleton((children_5 = ofArray([createElement("h1", {
        children: ["Login"],
    }), (elms_1 = ofArray([createElement("label", {
        children: "Username",
    }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Username"], ["value", state.Username], ["onChange", (ev) => {
        dispatch(new Msg(0, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = ofArray([createElement("label", {
        children: "Password",
    }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["placeholder", "Password"], ["value", state.Password], ["onChange", (ev_1) => {
        dispatch(new Msg(1, ev_1.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = singleton((props_11 = toList(delay(() => append(singleton_1(["className", "is-link"]), delay(() => append(state.Networking ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["disabled", !inputValid]), delay(() => append(singleton_1(["children", "Login"]), delay(() => singleton_1(["onClick", (e) => {
        e.preventDefault();
        dispatch(new Msg(2, new AsyncOperationStatus$1(0)));
    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_11))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("form", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    })));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
    });
}

