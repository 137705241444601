import { toString } from "../Client/fable_modules/fable-library.3.7.5/Types.js";
import { SecureToken } from "./Shared.fs.js";

export const tokenKey = "token";

export const agreedToRulesKey = "agreedToRules";

export function setAgreedToRules(value) {
    localStorage.setItem(agreedToRulesKey, toString(value));
}

export function getAgreedToRules() {
    const matchValue = localStorage.getItem(agreedToRulesKey);
    if (matchValue === null) {
        return false;
    }
    else {
        return matchValue === "true";
    }
}

export function saveToken(_arg1) {
    localStorage.setItem(tokenKey, _arg1.fields[0]);
}

export function getToken() {
    const matchValue = localStorage.getItem(tokenKey);
    if (matchValue === null) {
        return void 0;
    }
    else if (matchValue.trim().length === 0) {
        return void 0;
    }
    else {
        return new SecureToken(0, matchValue);
    }
}

export function removeToken() {
    localStorage.removeItem(tokenKey);
}

export function setBackgroundColor(color) {
    localStorage.setItem("backgroundColor", color);
}

export function setTextColor(color) {
    localStorage.setItem("textColor", color);
}

export function setLinkColor(color) {
    localStorage.setItem("linkColor", color);
}

export function getBackgroundColor() {
    return localStorage.getItem("backgroundColor");
}

export function getTextColor() {
    return localStorage.getItem("textColor");
}

export function getLinkColor() {
    return localStorage.getItem("linkColor");
}

