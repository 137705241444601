import { Union, Record } from "../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { union_type, record_type, option_type, class_type, lambda_type, unit_type, string_type, bool_type } from "../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { CaptchaToken as CaptchaToken_1, CaptchaToken$reflection } from "../Shared.fs.js";
import { Cmd_none } from "../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { join, isNullOrWhiteSpace, printf, toConsole } from "../../Client/fable_modules/fable-library.3.7.5/String.js";
import { createElement } from "react";
import { useHCaptcha_useHCaptchaRef } from "./HCaptcha.fs.js";
import { iterate, singleton, append, delay, toList } from "../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { createObj } from "../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { singleton as singleton_1, cons, ofArray } from "../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { config } from "../Config.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../../Client/fable_modules/Feliz.1.56.0/Interop.fs.js";
import react_hcaptcha from "@hcaptcha/react-hcaptcha";
import { toArray } from "../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../../Client/fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";

export class ComposeModule_State extends Record {
    constructor(UseUsernameInput, Username, OnUsernameChanged, UseSubject, Subject, OnSubjectChanged, Body, Preview, OnBodyChanged, File, OnFileChanged, UseCaptcha, CaptchaToken, OnCaptchaTokenChanged, OnSubmit, Show) {
        super();
        this.UseUsernameInput = UseUsernameInput;
        this.Username = Username;
        this.OnUsernameChanged = OnUsernameChanged;
        this.UseSubject = UseSubject;
        this.Subject = Subject;
        this.OnSubjectChanged = OnSubjectChanged;
        this.Body = Body;
        this.Preview = Preview;
        this.OnBodyChanged = OnBodyChanged;
        this.File = File;
        this.OnFileChanged = OnFileChanged;
        this.UseCaptcha = UseCaptcha;
        this.CaptchaToken = CaptchaToken;
        this.OnCaptchaTokenChanged = OnCaptchaTokenChanged;
        this.OnSubmit = OnSubmit;
        this.Show = Show;
    }
}

export function ComposeModule_State$reflection() {
    return record_type("Compose.ComposeModule.State", [], ComposeModule_State, () => [["UseUsernameInput", bool_type], ["Username", string_type], ["OnUsernameChanged", lambda_type(string_type, unit_type)], ["UseSubject", bool_type], ["Subject", string_type], ["OnSubjectChanged", lambda_type(string_type, unit_type)], ["Body", string_type], ["Preview", bool_type], ["OnBodyChanged", lambda_type(string_type, unit_type)], ["File", option_type(class_type("Browser.Types.File"))], ["OnFileChanged", lambda_type(class_type("Browser.Types.File"), unit_type)], ["UseCaptcha", bool_type], ["CaptchaToken", option_type(CaptchaToken$reflection())], ["OnCaptchaTokenChanged", lambda_type(CaptchaToken$reflection(), unit_type)], ["OnSubmit", lambda_type(unit_type, unit_type)], ["Show", bool_type]]);
}

export class ComposeModule_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "SetSubject", "SetBody", "SetFile", "SetToken", "SetPreview"];
    }
}

export function ComposeModule_Msg$reflection() {
    return union_type("Compose.ComposeModule.Msg", [], ComposeModule_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("Browser.Types.File")]], [["Item", CaptchaToken$reflection()]], [["Item", bool_type]]]);
}

export function ComposeModule_init(props) {
    return [new ComposeModule_State(props.useUsernameInput, props.username, (arg00) => {
        props.onUsernameChanged(arg00);
    }, props.useSubject, props.subject, (arg00_1) => {
        props.onSubjectChanged(arg00_1);
    }, props.body, props.preview, (arg00_2) => {
        props.onBodyChanged(arg00_2);
    }, props.file, (arg00_3) => {
        props.onFileChanged(arg00_3);
    }, props.useCaptcha, props.captchaToken, (arg00_4) => {
        props.onCaptchaTokenChanged(arg00_4);
    }, () => {
        props.onSubmit();
    }, props.show), Cmd_none()];
}

export class ComposeModule_DefaultState extends Record {
    constructor(UseUsernameInput, Username, UseSubject, UseCaptcha, Show) {
        super();
        this.UseUsernameInput = UseUsernameInput;
        this.Username = Username;
        this.UseSubject = UseSubject;
        this.UseCaptcha = UseCaptcha;
        this.Show = Show;
    }
    get useUsernameInput() {
        const s = this;
        return s.UseUsernameInput;
    }
    get username() {
        const s = this;
        return s.Username;
    }
    onUsernameChanged(_arg1) {
    }
    get useSubject() {
        const s = this;
        return s.UseSubject;
    }
    get subject() {
        return "";
    }
    onSubjectChanged(_arg2) {
    }
    get body() {
        return "";
    }
    get preview() {
        return false;
    }
    onBodyChanged(_arg3) {
    }
    get file() {
        return void 0;
    }
    onFileChanged(_arg4) {
    }
    get useCaptcha() {
        const s = this;
        return s.UseSubject;
    }
    get captchaToken() {
        return void 0;
    }
    onCaptchaTokenChanged(_arg5) {
    }
    onSubmit() {
    }
    get show() {
        const s = this;
        return s.Show;
    }
}

export function ComposeModule_DefaultState$reflection() {
    return record_type("Compose.ComposeModule.DefaultState", [], ComposeModule_DefaultState, () => [["UseUsernameInput", bool_type], ["Username", string_type], ["UseSubject", bool_type], ["UseCaptcha", bool_type], ["Show", bool_type]]);
}

export function ComposeModule_update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const subject = msg.fields[0];
            state.OnSubjectChanged(subject);
            return [new ComposeModule_State(state.UseUsernameInput, state.Username, state.OnUsernameChanged, state.UseSubject, subject, state.OnSubjectChanged, state.Body, state.Preview, state.OnBodyChanged, state.File, state.OnFileChanged, state.UseCaptcha, state.CaptchaToken, state.OnCaptchaTokenChanged, state.OnSubmit, state.Show), Cmd_none()];
        }
        case 2: {
            const body = msg.fields[0];
            state.OnBodyChanged(body);
            toConsole(printf("%s"))(body);
            return [new ComposeModule_State(state.UseUsernameInput, state.Username, state.OnUsernameChanged, state.UseSubject, state.Subject, state.OnSubjectChanged, body, state.Preview, state.OnBodyChanged, state.File, state.OnFileChanged, state.UseCaptcha, state.CaptchaToken, state.OnCaptchaTokenChanged, state.OnSubmit, state.Show), Cmd_none()];
        }
        case 3: {
            const file = msg.fields[0];
            state.OnFileChanged(file);
            return [new ComposeModule_State(state.UseUsernameInput, state.Username, state.OnUsernameChanged, state.UseSubject, state.Subject, state.OnSubjectChanged, state.Body, state.Preview, state.OnBodyChanged, file, state.OnFileChanged, state.UseCaptcha, state.CaptchaToken, state.OnCaptchaTokenChanged, state.OnSubmit, state.Show), Cmd_none()];
        }
        case 4: {
            const token = msg.fields[0];
            state.OnCaptchaTokenChanged(token);
            return [new ComposeModule_State(state.UseUsernameInput, state.Username, state.OnUsernameChanged, state.UseSubject, state.Subject, state.OnSubjectChanged, state.Body, state.Preview, state.OnBodyChanged, state.File, state.OnFileChanged, state.UseCaptcha, token, state.OnCaptchaTokenChanged, state.OnSubmit, state.Show), Cmd_none()];
        }
        case 5: {
            return [new ComposeModule_State(state.UseUsernameInput, state.Username, state.OnUsernameChanged, state.UseSubject, state.Subject, state.OnSubjectChanged, state.Body, msg.fields[0], state.OnBodyChanged, state.File, state.OnFileChanged, state.UseCaptcha, state.CaptchaToken, state.OnCaptchaTokenChanged, state.OnSubmit, state.Show), Cmd_none()];
        }
        default: {
            const username = msg.fields[0];
            state.OnUsernameChanged(username);
            return [new ComposeModule_State(state.UseUsernameInput, username, state.OnUsernameChanged, state.UseSubject, state.Subject, state.OnSubjectChanged, state.Body, state.Preview, state.OnBodyChanged, state.File, state.OnFileChanged, state.UseCaptcha, state.CaptchaToken, state.OnCaptchaTokenChanged, state.OnSubmit, state.Show), Cmd_none()];
        }
    }
}

export function ComposeModule_submitDisabled(state) {
    if (state.UseSubject) {
        if (isNullOrWhiteSpace(state.Subject) ? true : isNullOrWhiteSpace(state.Body)) {
            return true;
        }
        else {
            return state.File == null;
        }
    }
    else {
        return isNullOrWhiteSpace(state.Body);
    }
}

export function ComposeModule_fileRequiredMessage(state) {
    if (state.UseSubject) {
        if (state.File == null) {
            return createElement("div", {
                className: "has-text-danger",
                children: "Please choose media to upload with your post.",
            });
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}

export function ComposeModule_view(state, dispatch) {
    let elems_17, elms_11;
    const capthaRef = useHCaptcha_useHCaptchaRef();
    const showTab = (condition) => {
        if (state.Preview === condition) {
            return ["className", "is-active"];
        }
        else {
            return ["ref", void 0];
        }
    };
    if (state.Show) {
        const props_43 = ofArray([["id", "editor"], (elems_17 = [(elms_11 = toList(delay(() => {
            let elms, props_4, elems;
            return append(singleton((elms = ofArray([createElement("label", {
                children: "Username",
            }), (props_4 = ofArray([["id", "usernameInput"], (elems = toList(delay(() => (state.UseUsernameInput ? singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["value", state.Username], ["onChange", (ev) => {
                dispatch(new ComposeModule_Msg(0, ev.target.value));
            }]])))))) : singleton(createElement("b", {
                children: state.Username,
            }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("control", props_4))))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let elms_1, props_10, elems_2;
                return append(state.UseSubject ? singleton((elms_1 = ofArray([createElement("label", {
                    children: "Subject *",
                }), (props_10 = ofArray([["id", "subjectInput"], (elems_2 = [createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["maxLength", config.SubjectLengthLimit], ["value", state.Subject], ["onChange", (ev_1) => {
                    dispatch(new ComposeModule_Msg(1, ev_1.target.value));
                }]])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("div", createObj(Helpers_combineClasses("control", props_10))))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))) : singleton(null), delay(() => {
                    let elms_4, elms_2, children_2, elems_4, elems_5, elms_3;
                    return append(singleton((elms_4 = ofArray([(elms_2 = singleton_1((children_2 = ofArray([createElement("li", createObj(ofArray([showTab(false), (elems_4 = [createElement("a", {
                        children: "Edit",
                        onClick: (e) => {
                            e.preventDefault();
                            dispatch(new ComposeModule_Msg(5, false));
                        },
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("li", createObj(ofArray([showTab(true), (elems_5 = [createElement("a", {
                        children: "Preview",
                        onClick: (e_1) => {
                            e_1.preventDefault();
                            dispatch(new ComposeModule_Msg(5, true));
                        },
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))]), createElement("ul", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    }))), createElement("div", {
                        className: "tabs",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                    })), (elms_3 = toList(delay(() => ((!state.Preview) ? singleton(createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["id", "body"], ["placeholder", "Body"], ["maxLength", config.CommentBodyLengthLimit], ["value", state.Body], ["onChange", (ev_2) => {
                        dispatch(new ComposeModule_Msg(2, ev_2.target.value));
                    }]]))))) : singleton(createElement("p", {
                        dangerouslySetInnerHTML: {
                            __html: state.Body,
                        },
                    }))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))), delay(() => {
                        let elms_8, props_34, elems_12, elms_7, elms_6, elms_5, props_31;
                        return append(singleton((elms_8 = singleton_1((props_34 = ofArray([["className", "has-name"], ["id", "fileInput"], (elems_12 = [(elms_7 = ofArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["accept", "image/*,video/webm"], ["onChange", (ev_3) => {
                            const files = ev_3.target.files;
                            if ((!(files == null)) && (files.length > 0)) {
                                dispatch(new ComposeModule_Msg(3, files.item(0)));
                            }
                        }]]))))), (elms_6 = ofArray([(elms_5 = singleton_1(createElement("i", {
                            className: join(" ", ["fa", "fa-upload"]),
                        })), createElement("span", {
                            className: "file-icon",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                        })), createElement("span", createObj(Helpers_combineClasses("file-label", singleton_1(["children", "Upload Media"]))))]), createElement("span", {
                            className: "file-cta",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                        })), (props_31 = toList(delay(() => {
                            const matchValue_1 = state.File;
                            if (matchValue_1 == null) {
                                return singleton(["children", "Choose a file..."]);
                            }
                            else {
                                const file = matchValue_1;
                                return singleton(["children", file.name]);
                            }
                        })), createElement("span", createObj(Helpers_combineClasses("file-name", props_31))))]), createElement("label", {
                            className: "file-label",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]), createElement("div", createObj(Helpers_combineClasses("file", props_34))))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                        }))), delay(() => {
                            let elms_9;
                            return append(singleton((elms_9 = singleton_1(ComposeModule_fileRequiredMessage(state)), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                            }))), delay(() => append(state.UseCaptcha ? singleton(Interop_reactApi_1.createElement(react_hcaptcha, {
                                ref: capthaRef,
                                sitekey: config.CaptchaSiteKey,
                                onVerify: (token_1) => {
                                    dispatch(new ComposeModule_Msg(4, new CaptchaToken_1(0, token_1)));
                                },
                            })) : singleton(null), delay(() => {
                                let elms_10, props_39;
                                return singleton((elms_10 = singleton_1((props_39 = ofArray([["className", "is-link"], ["id", "submitButton"], ["disabled", ComposeModule_submitDisabled(state)], ["children", "Submit"], ["onClick", (e_2) => {
                                    e_2.preventDefault();
                                    state.OnSubmit();
                                    iterate((hcaptchaElement) => {
                                        hcaptchaElement.resetCaptcha();
                                    }, toArray(capthaRef.current));
                                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_39))))), createElement("div", {
                                    className: "control",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                                })));
                            }))));
                        }));
                    }));
                }));
            }));
        })), createElement("div", {
            className: "media-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]);
        return createElement("article", createObj(Helpers_combineClasses("media", props_43)));
    }
    else {
        return null;
    }
}

export function ComposeModule_compose(composeModule_composeInputProps) {
    const input = composeModule_composeInputProps.input;
    const patternInput = useFeliz_React__React_useElmish_Static_78C5B8C8(ComposeModule_init(input), ComposeModule_update, [input]);
    const capthaRef = useHCaptcha_useHCaptchaRef();
    return ComposeModule_view(patternInput[0], patternInput[1]);
}

export class Compose {
    constructor() {
    }
}

export function Compose$reflection() {
    return class_type("Compose.Compose", void 0, Compose);
}

