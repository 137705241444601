import { class_type } from "../fable-library.3.7.5/Reflection.js";
import { defaultArg, value, some } from "../fable-library.3.7.5/Option.js";
import { disposeSafe, isDisposable } from "../fable-library.3.7.5/Util.js";
import { ProgramModule_mkProgram, ProgramModule_withSetState, ProgramModule_runWith } from "../Fable.Elmish.3.1.0/program.fs.js";
import { React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useReact_useState_FCFD9EF } from "../Feliz.1.56.0/React.fs.js";

class ElmishObservable$2 {
    constructor() {
        this.hasDisposedOnce = false;
        this.state = (void 0);
        this.listener = (void 0);
        this.dispatcher = (void 0);
    }
}

function ElmishObservable$2$reflection(gen0, gen1) {
    return class_type("Feliz.UseElmish.UseElmishExtensions.ElmishObservable`2", [gen0, gen1], ElmishObservable$2);
}

function ElmishObservable$2_$ctor() {
    return new ElmishObservable$2();
}

function ElmishObservable$2__get_Value(_) {
    return _.state;
}

function ElmishObservable$2__get_HasDisposedOnce(_) {
    return _.hasDisposedOnce;
}

function ElmishObservable$2__SetState(_, model, dispatch) {
    _.state = some(model);
    _.dispatcher = dispatch;
    const matchValue = _.listener;
    if (matchValue != null) {
        matchValue(model);
    }
}

function ElmishObservable$2__Dispatch_2B594(_, msg) {
    const matchValue = _.dispatcher;
    if (matchValue != null) {
        matchValue(msg);
    }
}

function ElmishObservable$2__Subscribe_5028453F(_, f) {
    const matchValue = _.listener;
    if (matchValue == null) {
        _.listener = f;
    }
}

function ElmishObservable$2__DisposeState(_) {
    const matchValue = _.state;
    if (matchValue != null) {
        const matchValue_1 = value(matchValue);
        if (isDisposable(matchValue_1)) {
            disposeSafe(matchValue_1);
        }
    }
    _.dispatcher = (void 0);
    _.state = (void 0);
    _.hasDisposedOnce = true;
}

function runProgram(program, arg, obs, unitVar3) {
    ProgramModule_runWith(arg, ProgramModule_withSetState((arg00, arg10) => {
        ElmishObservable$2__SetState(obs, arg00, arg10);
    }, program()));
    const matchValue = ElmishObservable$2__get_Value(obs);
    if (matchValue != null) {
        return value(matchValue);
    }
    else {
        throw (new Error("Elmish program has not initialized"));
    }
}

export function disposeState(state) {
    const matchValue = state;
    if (isDisposable(matchValue)) {
        disposeSafe(matchValue);
    }
}

export function useFeliz_React__React_useElmish_Static_76E709(program, arg, dependencies) {
    const obs = useReact_useState_FCFD9EF(ElmishObservable$2_$ctor)[0];
    const patternInput_1 = useReact_useState_FCFD9EF(() => runProgram(program, arg, obs, void 0));
    const setState = patternInput_1[1];
    useReact_useEffect_Z5234A374(() => {
        if (ElmishObservable$2__get_HasDisposedOnce(obs)) {
            setState(runProgram(program, arg, obs, void 0));
        }
        return React_createDisposable_3A5B6456(() => {
            ElmishObservable$2__DisposeState(obs);
        });
    }, defaultArg(dependencies, []));
    ElmishObservable$2__Subscribe_5028453F(obs, setState);
    return [patternInput_1[0], (arg00) => {
        ElmishObservable$2__Dispatch_2B594(obs, arg00);
    }];
}

export function useFeliz_React__React_useElmish_Static_Z151674B(program, dependencies) {
    return useFeliz_React__React_useElmish_Static_76E709(program, void 0, dependencies);
}

export function useFeliz_React__React_useElmish_Static_Z4D826B22(init, update, arg, dependencies) {
    return useFeliz_React__React_useElmish_Static_76E709(() => ProgramModule_mkProgram(init, update, (_arg2, _arg1) => {
    }), arg, dependencies);
}

export function useFeliz_React__React_useElmish_Static_B42E862(init, update, dependencies) {
    return useFeliz_React__React_useElmish_Static_Z151674B(() => ProgramModule_mkProgram(init, update, (_arg4, _arg3) => {
    }), dependencies);
}

export function useFeliz_React__React_useElmish_Static_78C5B8C8(init, update, dependencies) {
    return useFeliz_React__React_useElmish_Static_Z151674B(() => ProgramModule_mkProgram(() => init, update, (_arg6, _arg5) => {
    }), dependencies);
}

