import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { Cmd_fromAsync, successToast, AsyncOperationStatus$1, SecureRequest$1, errorToast, IBoardApi$reflection, Route_builder } from "../../../Shared.fs.js";
import { Msg, State } from "./Types.fs.js";
import { Cmd_batch, Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { toString } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function update(msg, state) {
    let pattern_matching_result, password, password_1;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
        password_1 = msg.fields[0];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            if (state.networking) {
                pattern_matching_result = 2;
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else {
        pattern_matching_result = 0;
        password = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(password, state.newPasswordRepeat, state.networking, state.token), Cmd_none()];
        }
        case 1: {
            return [new State(state.newPassword, password_1, state.networking, state.token), Cmd_none()];
        }
        case 2: {
            return [state, Cmd_none()];
        }
        case 3: {
            if (msg.tag === 2) {
                if (msg.fields[0].tag === 1) {
                    if (msg.fields[0].fields[0].tag === 1) {
                        return [new State(state.newPassword, state.newPasswordRepeat, false, state.token), errorToast(toString(msg.fields[0].fields[0].fields[0]))];
                    }
                    else {
                        return [new State(state.newPassword, state.newPasswordRepeat, false, state.token), Cmd_none()];
                    }
                }
                else {
                    const resetPassword = singleton.Delay(() => {
                        const request = new SecureRequest$1(state.token, state.newPassword);
                        return singleton.Bind(boardApi.ResetPassword(request), (_arg1) => singleton.Return(new Msg(2, new AsyncOperationStatus$1(1, _arg1))));
                    });
                    return [new State(state.newPassword, state.newPasswordRepeat, true, state.token), Cmd_batch(ofArray([successToast("Password Reset!"), Cmd_fromAsync(resetPassword)]))];
                }
            }
            else {
                throw (new Error("Match failure: ResetPassword.Types.Msg"));
            }
        }
    }
}

