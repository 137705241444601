import { createElement } from "react";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";

export function render(state) {
    let elms;
    const elms_1 = singleton((elms = singleton(createElement("strong", {
        children: state.Message,
    })), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

