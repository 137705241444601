import { Record, Union } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { unit_type, tuple_type, record_type, string_type, bool_type, list_type, union_type, option_type, class_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { init as init_1, Msg$reflection as Msg$reflection_1, State$reflection as State$reflection_1 } from "./Comments/Types.fs.js";
import { GetPostsRequest, SecureRequest$1, GetPostsParameters, GetPostsParameters_empty, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, IBoardApi$reflection, Route_builder, AsyncOperationStatusWith$2$reflection, NetworkError$reflection, Comment$$reflection, AsyncOperationStatus$1$reflection, CaptchaToken$reflection, Deferred$1$reflection, Post$reflection, AppUser$reflection } from "../../../Shared.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { singleton } from "../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { fromBits } from "../../../../Client/fable_modules/fable-library.3.7.5/Long.js";
import { Cmd_none, Cmd_map } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { config } from "../../../Config.fs.js";
import { getToken } from "../../../LocalStore.fs.js";

export class Url extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Index", "Comments", "NotFound"];
    }
}

export function Url$reflection() {
    return union_type("Posts.Types.Url", [], Url, () => [[["boardId", class_type("System.Int64")]], [["postId", class_type("System.Int64")], ["commentId", option_type(class_type("System.Int64"))]], []]);
}

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Index", "Comments", "NotFound"];
    }
}

export function Page$reflection() {
    return union_type("Posts.Types.Page", [], Page, () => [[], [["Item", State$reflection_1()]], []]);
}

export class ViewMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Grid", "List"];
    }
}

export function ViewMode$reflection() {
    return union_type("Posts.Types.ViewMode", [], ViewMode, () => [[], []]);
}

export class State extends Record {
    constructor(CurrentUrl, CurrentPage, CurrentUser, BoardId, Posts, PreviousId, PreviousDate, Posting, Networking, Subject, Body, File, CaptchaToken, ViewMode, ShowCreatePost) {
        super();
        this.CurrentUrl = CurrentUrl;
        this.CurrentPage = CurrentPage;
        this.CurrentUser = CurrentUser;
        this.BoardId = BoardId;
        this.Posts = Posts;
        this.PreviousId = PreviousId;
        this.PreviousDate = PreviousDate;
        this.Posting = Posting;
        this.Networking = Networking;
        this.Subject = Subject;
        this.Body = Body;
        this.File = File;
        this.CaptchaToken = CaptchaToken;
        this.ViewMode = ViewMode;
        this.ShowCreatePost = ShowCreatePost;
    }
}

export function State$reflection() {
    return record_type("Posts.Types.State", [], State, () => [["CurrentUrl", Url$reflection()], ["CurrentPage", Page$reflection()], ["CurrentUser", AppUser$reflection()], ["BoardId", class_type("System.Int64")], ["Posts", Deferred$1$reflection(list_type(Post$reflection()))], ["PreviousId", option_type(class_type("System.Int64"))], ["PreviousDate", option_type(class_type("System.DateTimeOffset"))], ["Posting", bool_type], ["Networking", bool_type], ["Subject", string_type], ["Body", string_type], ["File", option_type(class_type("Browser.Types.File"))], ["CaptchaToken", option_type(CaptchaToken$reflection())], ["ViewMode", ViewMode$reflection()], ["ShowCreatePost", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UrlChanged", "NavigateTo", "CommentsMsg", "GetPosts", "LoadMorePosts", "SetUsername", "SetSubject", "SetBody", "SetFile", "SetCaptchaToken", "CreatePost", "CancelPost", "CancelledPost", "RemovePost", "BanIP", "SetView", "ShowCreatePost"];
    }
}

export function Msg$reflection() {
    return union_type("Posts.Types.Msg", [], Msg, () => [[["Item", Url$reflection()]], [["Item", string_type]], [["Item", Msg$reflection_1()]], [["Item", AsyncOperationStatus$1$reflection(list_type(Post$reflection()))]], [["Item", AsyncOperationStatus$1$reflection(list_type(Post$reflection()))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("Browser.Types.File")]], [["Item", CaptchaToken$reflection()]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(Post$reflection(), Comment$$reflection()), NetworkError$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(Post$reflection(), Comment$$reflection())]], [["ErrorValue", NetworkError$reflection()]]]))]], [], [], [["Item", AsyncOperationStatusWith$2$reflection(class_type("System.Int64"), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatusWith$2$reflection(tuple_type(string_type, option_type(class_type("System.DateTimeOffset"))), union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", ViewMode$reflection()]], [["Item", bool_type]]]);
}

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function goToUrl(dispatch, href, e) {
    e.preventDefault();
    dispatch(new Msg(1, href));
}

export function getPosts(req) {
    return singleton.Delay(() => singleton.Bind(boardApi.GetPosts(req), (_arg1) => singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, _arg1)))));
}

export function loadMorePosts(req) {
    return singleton.Delay(() => singleton.Bind(boardApi.GetPosts(req), (_arg1) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, _arg1)))));
}

export function init(currentUrl, user) {
    let content, inputRecord, matchValue;
    const defaultState = new State(currentUrl, new Page(0), user, fromBits(0, 0, false), new Deferred$1(0), void 0, void 0, false, false, "", "", void 0, void 0, new ViewMode(0), false);
    switch (currentUrl.tag) {
        case 1: {
            const patternInput = init_1(currentUrl.fields[0], currentUrl.fields[1], user);
            return [new State(defaultState.CurrentUrl, new Page(1, patternInput[0]), defaultState.CurrentUser, defaultState.BoardId, defaultState.Posts, defaultState.PreviousId, defaultState.PreviousDate, defaultState.Posting, defaultState.Networking, defaultState.Subject, defaultState.Body, defaultState.File, defaultState.CaptchaToken, defaultState.ViewMode, defaultState.ShowCreatePost), Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])];
        }
        case 2: {
            return [defaultState, Cmd_none()];
        }
        default: {
            const boardId = currentUrl.fields[0];
            return [new State(defaultState.CurrentUrl, defaultState.CurrentPage, defaultState.CurrentUser, boardId, defaultState.Posts, defaultState.PreviousId, defaultState.PreviousDate, defaultState.Posting, defaultState.Networking, defaultState.Subject, defaultState.Body, defaultState.File, defaultState.CaptchaToken, defaultState.ViewMode, defaultState.ShowCreatePost), Cmd_fromAsync(getPosts((content = ((inputRecord = GetPostsParameters_empty(), new GetPostsParameters(boardId, config.PostsSize, inputRecord.PreviousId, inputRecord.PreviousDate, inputRecord.UserId, inputRecord.IPAddress))), (matchValue = getToken(), (matchValue != null) ? (new GetPostsRequest(1, new SecureRequest$1(matchValue, content))) : (new GetPostsRequest(0, content))))))];
        }
    }
}

