import { Deferred$1 } from "./Shared.fs.js";

export function resolved(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return false;
        }
        case 2: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function inProgress(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return true;
        }
        case 2: {
            return false;
        }
        default: {
            return false;
        }
    }
}

export function map(transform, deferred) {
    switch (deferred.tag) {
        case 1: {
            return new Deferred$1(1);
        }
        case 2: {
            return new Deferred$1(2, transform(deferred.fields[0]));
        }
        default: {
            return new Deferred$1(0);
        }
    }
}

export function exists(predicate, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return false;
        }
        case 2: {
            return predicate(_arg1.fields[0]);
        }
        default: {
            return false;
        }
    }
}

export function bind(transform, deferred) {
    switch (deferred.tag) {
        case 1: {
            return new Deferred$1(1);
        }
        case 2: {
            return transform(deferred.fields[0]);
        }
        default: {
            return new Deferred$1(0);
        }
    }
}

