import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, Remoting_buildProxy_Z15584635 } from "../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { AsyncOperationStatus$1, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection, Board$reflection, AppUser$reflection, SecureToken$reflection, SecureToken, IBoardApi$reflection, Route_builder } from "../../Shared.fs.js";
import { isNullOrEmpty } from "../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { Record, Union } from "../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { init as init_2, Msg$reflection as Msg$reflection_5, State$reflection as State$reflection_7, Url$reflection as Url$reflection_1 } from "./Posts/Types.fs.js";
import { string_type, record_type, bool_type, list_type, union_type } from "../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { Msg$reflection as Msg$reflection_2, State$reflection as State$reflection_1 } from "./Login/Types.fs.js";
import { Msg$reflection as Msg$reflection_3, State$reflection as State$reflection_2 } from "./ForgotPassword/Types.fs.js";
import { init as init_1, Msg$reflection as Msg$reflection_4, State$reflection as State$reflection_3 } from "./ResetPassword/Types.fs.js";
import { State$reflection as State$reflection_4 } from "./EmailVerified/Types.fs.js";
import { Msg$reflection as Msg$reflection_1, State$reflection as State$reflection_5 } from "./Signup/Types.fs.js";
import { Msg$reflection as Msg$reflection_6, State$reflection as State$reflection_6 } from "./Profile/Types.fs.js";
import { Msg$reflection as Msg$reflection_8, State$reflection as State$reflection_8 } from "./Posts/Comments/Types.fs.js";
import { Msg$reflection as Msg$reflection_7, State$reflection as State$reflection_9 } from "./Admin/Types.fs.js";
import { parseUrl as parseUrl_1 } from "./Posts/View.fs.js";
import { ofArray, tail, head, isEmpty } from "../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { Route_$007CQuery$007C_$007C } from "../../../Client/fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { singleton } from "../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { getAgreedToRules, removeToken } from "../../LocalStore.fs.js";
import { RouterModule_urlSegments } from "../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { newGuid } from "../../../Client/fable_modules/fable-library.3.7.5/Guid.js";
import { add } from "../../../Client/fable_modules/fable-library.3.7.5/Map.js";
import { int64 } from "../../../Client/fable_modules/Thoth.Json.6.0.0/./Encode.fs.js";
import { Auto_generateBoxedDecoder_79988AEF, fromString, int64 as int64_1 } from "../../../Client/fable_modules/Thoth.Json.6.0.0/./Decode.fs.js";
import { empty } from "../../../Client/fable_modules/Thoth.Json.6.0.0/Extra.fs.js";
import { ExtraCoders } from "../../../Client/fable_modules/Thoth.Json.6.0.0/Types.fs.js";
import { uncurry } from "../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Cmd_batch, Cmd_map, Cmd_none } from "../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfFunc_result } from "../../../Client/fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";

export const boardApi = Remoting_buildProxy_Z15584635(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi())), {
    ResolveType: IBoardApi$reflection,
});

export function Route_$007CString$007C_$007C(input) {
    if (!isNullOrEmpty(input)) {
        return input;
    }
    else {
        return void 0;
    }
}

export function Route_$007CToken$007C_$007C(input) {
    if (!isNullOrEmpty(input)) {
        return new SecureToken(0, input);
    }
    else {
        return void 0;
    }
}

export class Url extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Index", "Signup", "Login", "ForgotPassword", "ResetPassword", "EmailVerified", "Logout", "Profile", "Posts", "Admin", "NotFound"];
    }
}

export function Url$reflection() {
    return union_type("Home.Types.Url", [], Url, () => [[], [], [], [], [["Item", SecureToken$reflection()]], [], [], [], [["Item", Url$reflection_1()]], [], []]);
}

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Index", "Login", "ForgotPassword", "ResetPassword", "EmailVerified", "Signup", "Profile", "NotFound", "Posts", "Comments", "Admin"];
    }
}

export function Page$reflection() {
    return union_type("Home.Types.Page", [], Page, () => [[], [["Item", State$reflection_1()]], [["Item", State$reflection_2()]], [["Item", State$reflection_3()]], [["Item", State$reflection_4()]], [["Item", State$reflection_5()]], [["Item", State$reflection_6()]], [], [["Item", State$reflection_7()]], [["Item", State$reflection_8()]], [["Item", State$reflection_9()]]]);
}

export function parseUrl(_arg1) {
    let pattern_matching_result, token;
    if (!isEmpty(_arg1)) {
        if (head(_arg1) === "signup") {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 5;
            }
        }
        else if (head(_arg1) === "login") {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 2;
            }
            else {
                pattern_matching_result = 5;
            }
        }
        else if (head(_arg1) === "forgot-password") {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 3;
            }
            else {
                pattern_matching_result = 5;
            }
        }
        else if (head(_arg1) === "reset-password") {
            if (!isEmpty(tail(_arg1))) {
                const activePatternResult38888 = Route_$007CQuery$007C_$007C(head(tail(_arg1)));
                if (activePatternResult38888 != null) {
                    if (!isEmpty(activePatternResult38888)) {
                        if (head(activePatternResult38888)[0] === "token") {
                            const activePatternResult38889 = Route_$007CToken$007C_$007C(head(activePatternResult38888)[1]);
                            if (activePatternResult38889 != null) {
                                if (isEmpty(tail(activePatternResult38888))) {
                                    if (isEmpty(tail(tail(_arg1)))) {
                                        pattern_matching_result = 4;
                                        token = activePatternResult38889;
                                    }
                                    else {
                                        pattern_matching_result = 5;
                                    }
                                }
                                else {
                                    pattern_matching_result = 5;
                                }
                            }
                            else {
                                pattern_matching_result = 5;
                            }
                        }
                        else {
                            pattern_matching_result = 5;
                        }
                    }
                    else {
                        pattern_matching_result = 5;
                    }
                }
                else {
                    pattern_matching_result = 5;
                }
            }
            else {
                pattern_matching_result = 5;
            }
        }
        else {
            pattern_matching_result = 5;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Url(0);
        }
        case 1: {
            return new Url(1);
        }
        case 2: {
            return new Url(2);
        }
        case 3: {
            return new Url(3);
        }
        case 4: {
            return new Url(4, token);
        }
        case 5: {
            let pattern_matching_result_1, postsSegments;
            if (!isEmpty(_arg1)) {
                if (head(_arg1) === "email-verified") {
                    if (isEmpty(tail(_arg1))) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 5;
                    }
                }
                else if (head(_arg1) === "logout") {
                    if (isEmpty(tail(_arg1))) {
                        pattern_matching_result_1 = 1;
                    }
                    else {
                        pattern_matching_result_1 = 5;
                    }
                }
                else if (head(_arg1) === "profile") {
                    if (isEmpty(tail(_arg1))) {
                        pattern_matching_result_1 = 2;
                    }
                    else {
                        pattern_matching_result_1 = 5;
                    }
                }
                else if (head(_arg1) === "boards") {
                    pattern_matching_result_1 = 3;
                    postsSegments = tail(_arg1);
                }
                else if (head(_arg1) === "admin") {
                    if (isEmpty(tail(_arg1))) {
                        pattern_matching_result_1 = 4;
                    }
                    else {
                        pattern_matching_result_1 = 5;
                    }
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else {
                pattern_matching_result_1 = 5;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new Url(5);
                }
                case 1: {
                    return new Url(6);
                }
                case 2: {
                    return new Url(7);
                }
                case 3: {
                    return new Url(8, parseUrl_1(postsSegments));
                }
                case 4: {
                    return new Url(9);
                }
                case 5: {
                    return new Url(10);
                }
            }
        }
    }
}

export class State extends Record {
    constructor(CurrentUser, Boards, AgreedToRules, CurrentUrl, CurrentPage) {
        super();
        this.CurrentUser = CurrentUser;
        this.Boards = Boards;
        this.AgreedToRules = AgreedToRules;
        this.CurrentUrl = CurrentUrl;
        this.CurrentPage = CurrentPage;
    }
}

export function State$reflection() {
    return record_type("Home.Types.State", [], State, () => [["CurrentUser", AppUser$reflection()], ["Boards", Deferred$1$reflection(list_type(Board$reflection()))], ["AgreedToRules", bool_type], ["CurrentUrl", Url$reflection()], ["CurrentPage", Page$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetUser", "SignupMsg", "LoginMsg", "ForgotPasswordMsg", "ResetPasswordMsg", "PostsMsg", "ProfileMsg", "AdminMsg", "CommentsMsg", "UrlChanged", "NavigateTo", "GetBoards", "AgreedToRules", "Initialize"];
    }
}

export function Msg$reflection() {
    return union_type("Home.Types.Msg", [], Msg, () => [[["Item", AppUser$reflection()]], [["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [["Item", Msg$reflection_7()]], [["Item", Msg$reflection_8()]], [["Item", Url$reflection()]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(list_type(Board$reflection()))]], [["Item", bool_type]], []]);
}

export const logout = singleton.Delay(() => {
    removeToken();
    return singleton.Bind(boardApi.Logout(), () => singleton.Return(new Msg(10, "/")));
});

export function init(user, unitVar1) {
    let copyOfStruct;
    const currentUrl = parseUrl(RouterModule_urlSegments(window.location.hash, 1));
    const extraCoders = new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], empty.Coders));
    let defaultState;
    let matchValue;
    const json = window.__INIT_STATE__;
    matchValue = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(State$reflection(), void 0, extraCoders)), json);
    if (matchValue.tag === 1) {
        console.error("Cannot decode init state", matchValue.fields[0], window.__INIT_STATE__);
        defaultState = (new State(user, new Deferred$1(0), getAgreedToRules(), currentUrl, new Page(0)));
    }
    else {
        defaultState = matchValue.fields[0];
    }
    switch (currentUrl.tag) {
        case 1: {
            return [defaultState, Cmd_none()];
        }
        case 2: {
            return [defaultState, Cmd_none()];
        }
        case 3: {
            return [defaultState, Cmd_none()];
        }
        case 4: {
            const patternInput = init_1(currentUrl.fields[0]);
            return [new State(defaultState.CurrentUser, defaultState.Boards, defaultState.AgreedToRules, defaultState.CurrentUrl, new Page(3, patternInput[0])), Cmd_map((arg0) => (new Msg(4, arg0)), patternInput[1])];
        }
        case 5: {
            return [defaultState, Cmd_none()];
        }
        case 6: {
            return [defaultState, Cmd_fromAsync(logout)];
        }
        case 7: {
            return [defaultState, Cmd_none()];
        }
        case 8: {
            const patternInput_1 = init_2(currentUrl.fields[0], user);
            return [new State(defaultState.CurrentUser, defaultState.Boards, defaultState.AgreedToRules, defaultState.CurrentUrl, new Page(8, patternInput_1[0])), Cmd_map((arg0_1) => (new Msg(5, arg0_1)), patternInput_1[1])];
        }
        case 9: {
            return [defaultState, Cmd_none()];
        }
        case 10: {
            return [defaultState, Cmd_none()];
        }
        default: {
            return [defaultState, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(13)), Cmd_OfFunc_result(new Msg(11, new AsyncOperationStatus$1(0)))]))];
        }
    }
}

