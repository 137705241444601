import { cancel as cancel_1, createCancellationToken } from "../../../../../Client/fable_modules/fable-library.3.7.5/Async.js";
import { Cmd_batch, Cmd_none, Cmd_ofSub } from "../../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toConsole } from "../../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { IPBanRequest, successToast, BanRequest, UserBanRequest, AsyncOperationStatusWith$2, AnonymousRequest$1, CaptchaToken as CaptchaToken_2, CreateCommentRequest, CreateCommentData, errorToast, AppUser, AnonymousUser, GetCommentsRequest, SecureRequest$1, GetCommentsParameters, GetCommentsParameters_empty, Cmd_fromAsync, List_last, Deferred$1, AsyncOperationStatus$1 } from "../../../../Shared.fs.js";
import { getUser, scrollToBottom, getComments, boardApi, getMoreComments, State, Msg } from "./Types.fs.js";
import { Cmd_ofSub as Cmd_ofSub_1 } from "../../../../../Client/fable_modules/Feliz.Router.3.8.0/../Fable.Elmish.3.1.0/cmd.fs.js";
import { RouterModule_nav } from "../../../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { ofArray, append, length, singleton } from "../../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { config } from "../../../../Config.fs.js";
import { getToken } from "../../../../LocalStore.fs.js";
import { map } from "../../../../Deferred.fs.js";
import { List_distinct } from "../../../../../Client/fable_modules/fable-library.3.7.5/Seq2.js";
import { safeHash, equals } from "../../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { singleton as singleton_1 } from "../../../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { initialize } from "../../../../../Client/fable_modules/fable-library.3.7.5/Array.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../../../../../Client/fable_modules/Fable.Remoting.Client.7.16.0/Extensions.fs.js";
import { defaultArg, map as map_1 } from "../../../../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { toString } from "../../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { FSharpResult$2 } from "../../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { utcNow } from "../../../../../Client/fable_modules/fable-library.3.7.5/DateOffset.js";

let cts = createCancellationToken();

export function subscribe(initial) {
    return Cmd_ofSub((dispatch) => {
        window.onscroll = ((e) => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                toConsole(printf("rock bottom"));
                dispatch(new Msg(2, new AsyncOperationStatus$1(0)));
            }
        });
    });
}

export function update(msg, state) {
    let content, inputRecord, matchValue_1, content_2, inputRecord_1, matchValue_7, content_3, inputRecord_2, matchValue_9;
    let pattern_matching_result, href;
    if (msg.tag === 0) {
        pattern_matching_result = 0;
        href = msg.fields[0];
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 0) {
            if (equals(state.Comments, new Deferred$1(1))) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [state, Cmd_ofSub_1((_arg138) => {
                RouterModule_nav(singleton(href), 1, 2);
            })];
        }
        case 1: {
            return [state, Cmd_none()];
        }
        case 2: {
            let pattern_matching_result_1, comments;
            if (msg.tag === 1) {
                if (msg.fields[0].tag === 1) {
                    pattern_matching_result_1 = 1;
                    comments = msg.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (msg.tag === 2) {
                if (msg.fields[0].tag === 0) {
                    if (state.Networking) {
                        pattern_matching_result_1 = 2;
                    }
                    else {
                        pattern_matching_result_1 = 3;
                    }
                }
                else {
                    pattern_matching_result_1 = 3;
                }
            }
            else {
                pattern_matching_result_1 = 3;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [state, Cmd_none()];
                }
                case 1: {
                    const nextState = new State(state.CurrentUser, state.PostId, new Deferred$1(2, comments), state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, state.Posting, state.ShowUserPanel, state.PanelUser);
                    const matchValue = List_last(comments);
                    if (matchValue == null) {
                        return [nextState, Cmd_none()];
                    }
                    else {
                        const lastComment = matchValue;
                        return [new State(nextState.CurrentUser, nextState.PostId, nextState.Comments, lastComment.Id, lastComment.CreatedAt, nextState.Body, nextState.File, nextState.CaptchaToken, nextState.ReplyTo, nextState.Networking, nextState.Posting, nextState.ShowUserPanel, nextState.PanelUser), Cmd_none()];
                    }
                }
                case 2: {
                    return [state, Cmd_none()];
                }
                case 3: {
                    let pattern_matching_result_2, comments_1, username, body, file, token_1;
                    if (msg.tag === 2) {
                        if (msg.fields[0].tag === 1) {
                            pattern_matching_result_2 = 1;
                            comments_1 = msg.fields[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 0;
                        }
                    }
                    else if (msg.tag === 3) {
                        pattern_matching_result_2 = 2;
                        username = msg.fields[0];
                    }
                    else if (msg.tag === 4) {
                        pattern_matching_result_2 = 3;
                        body = msg.fields[0];
                    }
                    else if (msg.tag === 5) {
                        pattern_matching_result_2 = 4;
                        file = msg.fields[0];
                    }
                    else if (msg.tag === 6) {
                        pattern_matching_result_2 = 5;
                        token_1 = msg.fields[0];
                    }
                    else if (msg.tag === 7) {
                        if (msg.fields[0].tag === 0) {
                            if (state.Posting) {
                                pattern_matching_result_2 = 6;
                            }
                            else {
                                pattern_matching_result_2 = 7;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 7;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 7;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, true, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(getMoreComments((content = ((inputRecord = GetCommentsParameters_empty(), new GetCommentsParameters(state.PostId, inputRecord.CommentId, config.CommentsSize, state.PreviousId, state.PreviousDate, inputRecord.UserId, inputRecord.IPAddress))), (matchValue_1 = getToken(), (matchValue_1 != null) ? (new GetCommentsRequest(1, new SecureRequest$1(matchValue_1, content))) : (new GetCommentsRequest(0, content))))))];
                        }
                        case 1: {
                            const arg10 = length(comments_1) | 0;
                            toConsole(printf("%i comments loaded"))(arg10);
                            const nextState_1 = new State(state.CurrentUser, state.PostId, map((cs) => List_distinct(append(cs, comments_1), {
                                Equals: equals,
                                GetHashCode: safeHash,
                            }), state.Comments), state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, false, state.Posting, state.ShowUserPanel, state.PanelUser);
                            const matchValue_2 = List_last(comments_1);
                            if (matchValue_2 == null) {
                                return [nextState_1, Cmd_none()];
                            }
                            else {
                                const lastComment_1 = matchValue_2;
                                return [new State(nextState_1.CurrentUser, nextState_1.PostId, nextState_1.Comments, lastComment_1.Id, lastComment_1.CreatedAt, nextState_1.Body, nextState_1.File, nextState_1.CaptchaToken, nextState_1.ReplyTo, nextState_1.Networking, nextState_1.Posting, nextState_1.ShowUserPanel, nextState_1.PanelUser), Cmd_none()];
                            }
                        }
                        case 2: {
                            if (state.CurrentUser.tag === 1) {
                                return [state, Cmd_none()];
                            }
                            else {
                                return [new State(new AppUser(0, new AnonymousUser(username)), state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_none()];
                            }
                        }
                        case 3: {
                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_none()];
                        }
                        case 4: {
                            if (file.size > config.FileSizeLimit) {
                                return [state, errorToast("File too big. Limit is 4mb.")];
                            }
                            else {
                                return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, file, state.CaptchaToken, state.ReplyTo, state.Networking, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_none()];
                            }
                        }
                        case 5: {
                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, token_1, state.ReplyTo, state.Networking, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_none()];
                        }
                        case 6: {
                            return [state, Cmd_none()];
                        }
                        case 7: {
                            let pattern_matching_result_3, err;
                            if (msg.tag === 7) {
                                if (msg.fields[0].tag === 1) {
                                    if (msg.fields[0].fields[0].tag === 1) {
                                        pattern_matching_result_3 = 2;
                                        err = msg.fields[0].fields[0].fields[0];
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 0;
                                }
                            }
                            else if (msg.tag === 8) {
                                pattern_matching_result_3 = 3;
                            }
                            else if (msg.tag === 9) {
                                pattern_matching_result_3 = 4;
                            }
                            else if (msg.tag === 10) {
                                if (msg.fields[0].tag === 0) {
                                    if (state.Networking) {
                                        pattern_matching_result_3 = 5;
                                    }
                                    else {
                                        pattern_matching_result_3 = 6;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 6;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 6;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    cts = createCancellationToken();
                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, true, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(singleton_1.Delay(() => singleton_1.Bind(singleton_1.Delay(() => {
                                        const matchValue_4 = state.File;
                                        if (matchValue_4 == null) {
                                            const bytes_1 = initialize(0, (value) => (value & 0xFF), Uint8Array);
                                            return singleton_1.Return([bytes_1, void 0]);
                                        }
                                        else {
                                            const f = matchValue_4;
                                            return singleton_1.Bind(Browser_Types_File__File_ReadAsByteArray(f), (_arg1) => singleton_1.Return([_arg1, f.name]));
                                        }
                                    }), (_arg2) => {
                                        const content_1 = new CreateCommentData(state.PostId, map_1((comment) => comment.Id, state.ReplyTo), state.Body, _arg2[1]);
                                        let request_3;
                                        const matchValue_5 = state.CurrentUser;
                                        if (matchValue_5.tag === 1) {
                                            const matchValue_6 = getToken();
                                            if (matchValue_6 == null) {
                                                throw (new Error("Logged in but no token in localstore"));
                                            }
                                            else {
                                                request_3 = (new CreateCommentRequest(1, new SecureRequest$1(matchValue_6, content_1)));
                                            }
                                        }
                                        else {
                                            request_3 = (new CreateCommentRequest(0, new AnonymousRequest$1(matchValue_5.fields[0].Username, defaultArg(state.CaptchaToken, new CaptchaToken_2(0, "")), content_1)));
                                        }
                                        return singleton_1.Bind(boardApi.CreateComment(request_3, _arg2[0], cts), (_arg3) => singleton_1.Return(new Msg(7, new AsyncOperationStatus$1(1, _arg3))));
                                    })))];
                                }
                                case 1: {
                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, "", void 0, void 0, void 0, state.Networking, false, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(getComments((content_2 = ((inputRecord_1 = GetCommentsParameters_empty(), new GetCommentsParameters(state.PostId, inputRecord_1.CommentId, config.CommentsSize, inputRecord_1.PreviousId, inputRecord_1.PreviousDate, inputRecord_1.UserId, inputRecord_1.IPAddress))), (matchValue_7 = getToken(), (matchValue_7 != null) ? (new GetCommentsRequest(1, new SecureRequest$1(matchValue_7, content_2))) : (new GetCommentsRequest(0, content_2))))))];
                                }
                                case 2: {
                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, false, state.ShowUserPanel, state.PanelUser), errorToast(toString(err))];
                                }
                                case 3: {
                                    return [state, Cmd_fromAsync(singleton_1.Delay(() => {
                                        cancel_1(cts);
                                        return singleton_1.Return(new Msg(9));
                                    }))];
                                }
                                case 4: {
                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, false, state.ShowUserPanel, state.PanelUser), Cmd_none()];
                                }
                                case 5: {
                                    return [state, Cmd_none()];
                                }
                                case 6: {
                                    let pattern_matching_result_4, commentId, err_1, comment_2;
                                    if (msg.tag === 10) {
                                        if (msg.fields[0].tag === 1) {
                                            if (msg.fields[0].fields[0].tag === 1) {
                                                pattern_matching_result_4 = 2;
                                                err_1 = msg.fields[0].fields[0].fields[0];
                                            }
                                            else {
                                                pattern_matching_result_4 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_4 = 0;
                                            commentId = msg.fields[0].fields[0];
                                        }
                                    }
                                    else if (msg.tag === 11) {
                                        pattern_matching_result_4 = 3;
                                        comment_2 = msg.fields[0];
                                    }
                                    else if (msg.tag === 12) {
                                        pattern_matching_result_4 = 4;
                                    }
                                    else if (msg.tag === 13) {
                                        if (msg.fields[0].tag === 0) {
                                            if (equals(state.PanelUser, new Deferred$1(1))) {
                                                pattern_matching_result_4 = 5;
                                            }
                                            else {
                                                pattern_matching_result_4 = 6;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_4 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 6;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, true, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(singleton_1.Delay(() => {
                                                const matchValue_8 = getToken();
                                                if (matchValue_8 == null) {
                                                    return singleton_1.Return(new Msg(10, new AsyncOperationStatusWith$2(1, new FSharpResult$2(0, void 0))));
                                                }
                                                else {
                                                    const request_5 = new SecureRequest$1(matchValue_8, commentId);
                                                    return singleton_1.Bind(boardApi.RemoveComment(request_5), (_arg4) => singleton_1.Return(new Msg(10, new AsyncOperationStatusWith$2(1, _arg4))));
                                                }
                                            }))];
                                        }
                                        case 1: {
                                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, false, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(getComments((content_3 = ((inputRecord_2 = GetCommentsParameters_empty(), new GetCommentsParameters(state.PostId, inputRecord_2.CommentId, config.CommentsSize, inputRecord_2.PreviousId, inputRecord_2.PreviousDate, inputRecord_2.UserId, inputRecord_2.IPAddress))), (matchValue_9 = getToken(), (matchValue_9 != null) ? (new GetCommentsRequest(1, new SecureRequest$1(matchValue_9, content_3))) : (new GetCommentsRequest(0, content_3))))))];
                                        }
                                        case 2: {
                                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, false, state.Posting, state.ShowUserPanel, state.PanelUser), errorToast(err_1)];
                                        }
                                        case 3: {
                                            scrollToBottom();
                                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, comment_2, state.Networking, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_none()];
                                        }
                                        case 4: {
                                            return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, state.Posting, !state.ShowUserPanel, state.PanelUser), Cmd_none()];
                                        }
                                        case 5: {
                                            return [state, Cmd_none()];
                                        }
                                        case 6: {
                                            let pattern_matching_result_5, userId, user_2, err_2;
                                            if (msg.tag === 13) {
                                                if (msg.fields[0].tag === 1) {
                                                    if (msg.fields[0].fields[0].tag === 1) {
                                                        pattern_matching_result_5 = 2;
                                                        err_2 = msg.fields[0].fields[0].fields[0];
                                                    }
                                                    else {
                                                        pattern_matching_result_5 = 1;
                                                        user_2 = msg.fields[0].fields[0].fields[0];
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_5 = 0;
                                                    userId = msg.fields[0].fields[0];
                                                }
                                            }
                                            else if (msg.tag === 14) {
                                                if (msg.fields[0].tag === 0) {
                                                    if (equals(state.PanelUser, new Deferred$1(1))) {
                                                        pattern_matching_result_5 = 3;
                                                    }
                                                    else {
                                                        pattern_matching_result_5 = 4;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_5 = 4;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_5 = 4;
                                            }
                                            switch (pattern_matching_result_5) {
                                                case 0: {
                                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, true, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(getUser(userId))];
                                                }
                                                case 1: {
                                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, state.Networking, state.Posting, state.ShowUserPanel, new Deferred$1(2, user_2)), Cmd_none()];
                                                }
                                                case 2: {
                                                    return [state, errorToast(toString(err_2))];
                                                }
                                                case 3: {
                                                    return [state, Cmd_none()];
                                                }
                                                case 4: {
                                                    let pattern_matching_result_6, userId_1, err_3;
                                                    if (msg.tag === 14) {
                                                        if (msg.fields[0].tag === 1) {
                                                            if (msg.fields[0].fields[0].tag === 1) {
                                                                pattern_matching_result_6 = 2;
                                                                err_3 = msg.fields[0].fields[0].fields[0];
                                                            }
                                                            else {
                                                                pattern_matching_result_6 = 1;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_6 = 0;
                                                            userId_1 = msg.fields[0].fields[0];
                                                        }
                                                    }
                                                    else if (msg.tag === 15) {
                                                        if (msg.fields[0].tag === 0) {
                                                            if (state.Networking) {
                                                                pattern_matching_result_6 = 3;
                                                            }
                                                            else {
                                                                pattern_matching_result_6 = 4;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_6 = 4;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_6 = 4;
                                                    }
                                                    switch (pattern_matching_result_6) {
                                                        case 0: {
                                                            return [state, Cmd_batch(ofArray([Cmd_fromAsync(singleton_1.Delay(() => {
                                                                const matchValue_10 = getToken();
                                                                if (matchValue_10 == null) {
                                                                    return singleton_1.Return(new Msg(14, new AsyncOperationStatusWith$2(1, new FSharpResult$2(1, "could not ban user"))));
                                                                }
                                                                else {
                                                                    const request_7 = new SecureRequest$1(matchValue_10, new BanRequest(1, new UserBanRequest(userId_1, utcNow(), void 0)));
                                                                    return singleton_1.Bind(boardApi.Ban(request_7), (_arg5) => singleton_1.Return(new Msg(14, new AsyncOperationStatusWith$2(1, _arg5))));
                                                                }
                                                            })), Cmd_fromAsync(getUser(userId_1))]))];
                                                        }
                                                        case 1: {
                                                            return [state, successToast("Banned user")];
                                                        }
                                                        case 2: {
                                                            return [state, errorToast(err_3)];
                                                        }
                                                        case 3: {
                                                            return [state, Cmd_none()];
                                                        }
                                                        case 4: {
                                                            if (msg.tag === 15) {
                                                                if (msg.fields[0].tag === 1) {
                                                                    if (msg.fields[0].fields[0].tag === 1) {
                                                                        return [state, errorToast(msg.fields[0].fields[0].fields[0])];
                                                                    }
                                                                    else {
                                                                        return [state, successToast("IP Banned")];
                                                                    }
                                                                }
                                                                else {
                                                                    return [new State(state.CurrentUser, state.PostId, state.Comments, state.PreviousId, state.PreviousDate, state.Body, state.File, state.CaptchaToken, state.ReplyTo, true, state.Posting, state.ShowUserPanel, state.PanelUser), Cmd_fromAsync(singleton_1.Delay(() => {
                                                                        const matchValue_11 = getToken();
                                                                        if (matchValue_11 == null) {
                                                                            return singleton_1.Return(new Msg(15, new AsyncOperationStatusWith$2(1, new FSharpResult$2(0, void 0))));
                                                                        }
                                                                        else {
                                                                            const request_8 = new SecureRequest$1(matchValue_11, new BanRequest(0, new IPBanRequest(msg.fields[0].fields[0][0], msg.fields[0].fields[0][1])));
                                                                            return singleton_1.Bind(boardApi.Ban(request_8), (_arg6) => singleton_1.Return(new Msg(15, new AsyncOperationStatusWith$2(1, _arg6))));
                                                                        }
                                                                    }))];
                                                                }
                                                            }
                                                            else {
                                                                throw (new Error("Match failure: Comments.Types.Msg"));
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

