import { update as update_1 } from "./Signup/State.fs.js";
import { boardApi, logout, Msg, State, Page } from "./Types.fs.js";
import { Cmd_none, Cmd_map } from "../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { init as init_1, $007CUserLoggedIn$007C_$007C } from "./Login/Types.fs.js";
import { getAgreedToRules, setAgreedToRules, saveToken } from "../../LocalStore.fs.js";
import { AsyncOperationStatus$1, Deferred$1, UserModule_Role, Cmd_fromAsync, AnonymousUser_create, AppUser } from "../../Shared.fs.js";
import { Cmd_ofSub } from "../../../Client/fable_modules/Feliz.Router.3.8.0/../Fable.Elmish.3.1.0/cmd.fs.js";
import { RouterModule_nav } from "../../../Client/fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { singleton } from "../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { update as update_2 } from "./Login/State.fs.js";
import { update as update_3 } from "./ForgotPassword/State.fs.js";
import { update as update_4 } from "./ResetPassword/State.fs.js";
import { update as update_5 } from "./Profile/State.fs.js";
import { update as update_6 } from "./Admin/State.fs.js";
import { subscription as subscription_1, update as update_7 } from "./Posts/State.fs.js";
import { subscribe, update as update_8 } from "./Posts/Comments/State.fs.js";
import { init } from "./Signup/Types.fs.js";
import { init as init_2 } from "./ForgotPassword/Types.fs.js";
import { init as init_3 } from "./ResetPassword/Types.fs.js";
import { init as init_4 } from "./EmailVerified/Types.fs.js";
import { init as init_5 } from "./Profile/Types.fs.js";
import { FSharpSet__Contains } from "../../../Client/fable_modules/fable-library.3.7.5/Set.js";
import { init as init_6 } from "./Admin/Types.fs.js";
import { init as init_7 } from "./Posts/Types.fs.js";
import { singleton as singleton_1 } from "../../../Client/fable_modules/fable-library.3.7.5/AsyncBuilder.js";
import { equals } from "../../../Client/fable_modules/fable-library.3.7.5/Util.js";

export function update(msg, state) {
    const matchValue = [msg, state.CurrentPage];
    let pattern_matching_result, signupMsg, signupState, loginMsg, loginState, forgotPasswordMsg, forgotPasswordState, resetPasswordMsg, resetPasswordState, profileMsg, profileState, adminMsg, adminState, postsMsg, postsState, commentsState, postsMsg_1, nextUrl, href;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 5) {
            pattern_matching_result = 0;
            signupMsg = matchValue[0].fields[0];
            signupState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 1;
            loginMsg = matchValue[0].fields[0];
            loginState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 2;
            forgotPasswordMsg = matchValue[0].fields[0];
            forgotPasswordState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 3;
            resetPasswordMsg = matchValue[0].fields[0];
            resetPasswordState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 4;
            profileMsg = matchValue[0].fields[0];
            profileState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[1].tag === 10) {
            pattern_matching_result = 5;
            adminMsg = matchValue[0].fields[0];
            adminState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].tag === 8) {
            pattern_matching_result = 6;
            postsMsg = matchValue[0].fields[0];
            postsState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[1].tag === 9) {
            pattern_matching_result = 7;
            commentsState = matchValue[1].fields[0];
            postsMsg_1 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 9) {
        pattern_matching_result = 8;
        nextUrl = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 10) {
        pattern_matching_result = 9;
        href = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 11) {
        if (matchValue[0].fields[0].tag === 0) {
            if (matchValue[1].tag === 0) {
                if (equals(state.Boards, new Deferred$1(1))) {
                    pattern_matching_result = 10;
                }
                else {
                    pattern_matching_result = 11;
                }
            }
            else {
                pattern_matching_result = 11;
            }
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else {
        pattern_matching_result = 11;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(signupMsg, signupState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(5, patternInput[0])), Cmd_map((arg0) => (new Msg(1, arg0)), patternInput[1])];
        }
        case 1: {
            const activePatternResult38930 = $007CUserLoggedIn$007C_$007C(loginMsg);
            if (activePatternResult38930 != null) {
                const res = activePatternResult38930;
                saveToken(res.Token);
                return [new State(new AppUser(1, res.User), state.Boards, state.AgreedToRules, state.CurrentUrl, state.CurrentPage), Cmd_ofSub((_arg138) => {
                    RouterModule_nav(singleton("/"), 1, 2);
                })];
            }
            else {
                const patternInput_1 = update_2(loginMsg, loginState);
                return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(1, patternInput_1[0])), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
            }
        }
        case 2: {
            const patternInput_2 = update_3(forgotPasswordMsg, forgotPasswordState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(2, patternInput_2[0])), Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_2[1])];
        }
        case 3: {
            const patternInput_3 = update_4(resetPasswordMsg, resetPasswordState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(3, patternInput_3[0])), Cmd_map((arg0_3) => (new Msg(4, arg0_3)), patternInput_3[1])];
        }
        case 4: {
            const patternInput_4 = update_5(profileMsg, profileState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(6, patternInput_4[0])), Cmd_map((arg0_4) => (new Msg(6, arg0_4)), patternInput_4[1])];
        }
        case 5: {
            const patternInput_5 = update_6(adminMsg, adminState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(10, patternInput_5[0])), Cmd_map((arg0_5) => (new Msg(7, arg0_5)), patternInput_5[1])];
        }
        case 6: {
            const patternInput_6 = update_7(postsMsg, postsState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(8, patternInput_6[0])), Cmd_map((arg0_6) => (new Msg(5, arg0_6)), patternInput_6[1])];
        }
        case 7: {
            const patternInput_7 = update_8(postsMsg_1, commentsState);
            return [new State(state.CurrentUser, state.Boards, state.AgreedToRules, state.CurrentUrl, new Page(9, patternInput_7[0])), Cmd_map((arg0_7) => (new Msg(8, arg0_7)), patternInput_7[1])];
        }
        case 8: {
            const show = (page) => (new State(state.CurrentUser, state.Boards, state.AgreedToRules, nextUrl, page));
            switch (nextUrl.tag) {
                case 10: {
                    return [show(new Page(7)), Cmd_none()];
                }
                case 1: {
                    const patternInput_8 = init();
                    return [show(new Page(5, patternInput_8[0])), Cmd_map((arg0_8) => (new Msg(1, arg0_8)), patternInput_8[1])];
                }
                case 2: {
                    const patternInput_9 = init_1();
                    return [show(new Page(1, patternInput_9[0])), Cmd_map((arg0_9) => (new Msg(2, arg0_9)), patternInput_9[1])];
                }
                case 3: {
                    const patternInput_10 = init_2();
                    return [show(new Page(2, patternInput_10[0])), Cmd_map((arg0_10) => (new Msg(3, arg0_10)), patternInput_10[1])];
                }
                case 4: {
                    const patternInput_11 = init_3(nextUrl.fields[0]);
                    return [show(new Page(3, patternInput_11[0])), Cmd_map((arg0_11) => (new Msg(4, arg0_11)), patternInput_11[1])];
                }
                case 5: {
                    return [show(new Page(4, init_4("")[0])), Cmd_none()];
                }
                case 6: {
                    return [new State(new AppUser(0, AnonymousUser_create()), state.Boards, state.AgreedToRules, state.CurrentUrl, state.CurrentPage), Cmd_fromAsync(logout)];
                }
                case 7: {
                    const matchValue_1 = state.CurrentUser;
                    if (matchValue_1.tag === 0) {
                        return [state, Cmd_ofSub((_arg138_1) => {
                            RouterModule_nav(singleton("/"), 1, 2);
                        })];
                    }
                    else {
                        const patternInput_13 = init_5(matchValue_1.fields[0]);
                        return [show(new Page(6, patternInput_13[0])), Cmd_map((arg0_12) => (new Msg(6, arg0_12)), patternInput_13[1])];
                    }
                }
                case 9: {
                    const matchValue_2 = state.CurrentUser;
                    if (matchValue_2.tag === 0) {
                        return [state, Cmd_ofSub((_arg138_3) => {
                            RouterModule_nav(singleton("/"), 1, 2);
                        })];
                    }
                    else {
                        const user_1 = matchValue_2.fields[0];
                        if (FSharpSet__Contains(user_1.Roles, new UserModule_Role(2))) {
                            const patternInput_14 = init_6(user_1);
                            return [show(new Page(10, patternInput_14[0])), Cmd_map((arg0_13) => (new Msg(7, arg0_13)), patternInput_14[1])];
                        }
                        else {
                            return [state, Cmd_ofSub((_arg138_2) => {
                                RouterModule_nav(singleton("/"), 1, 2);
                            })];
                        }
                    }
                }
                case 8: {
                    const patternInput_15 = init_7(nextUrl.fields[0], state.CurrentUser);
                    return [show(new Page(8, patternInput_15[0])), Cmd_map((arg0_14) => (new Msg(5, arg0_14)), patternInput_15[1])];
                }
                default: {
                    return [show(new Page(0)), Cmd_none()];
                }
            }
        }
        case 9: {
            return [state, Cmd_ofSub((_arg138_4) => {
                RouterModule_nav(singleton(href), 1, 2);
            })];
        }
        case 10: {
            return [state, Cmd_none()];
        }
        case 11: {
            let pattern_matching_result_1, boards_1, user_2, agreed;
            if (matchValue[0].tag === 11) {
                if (matchValue[0].fields[0].tag === 1) {
                    if (matchValue[1].tag === 0) {
                        pattern_matching_result_1 = 1;
                        boards_1 = matchValue[0].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 5;
                    }
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else if (matchValue[0].tag === 0) {
                pattern_matching_result_1 = 2;
                user_2 = matchValue[0].fields[0];
            }
            else if (matchValue[0].tag === 12) {
                pattern_matching_result_1 = 3;
                agreed = matchValue[0].fields[0];
            }
            else if (matchValue[0].tag === 13) {
                pattern_matching_result_1 = 4;
            }
            else {
                pattern_matching_result_1 = 5;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new State(state.CurrentUser, new Deferred$1(1), state.AgreedToRules, state.CurrentUrl, state.CurrentPage), Cmd_fromAsync(singleton_1.Delay(() => singleton_1.Bind(boardApi.GetBoards(), (_arg1) => singleton_1.Return(new Msg(11, new AsyncOperationStatus$1(1, _arg1))))))];
                }
                case 1: {
                    return [new State(state.CurrentUser, new Deferred$1(2, boards_1), state.AgreedToRules, state.CurrentUrl, state.CurrentPage), Cmd_none()];
                }
                case 2: {
                    return [new State(user_2, state.Boards, state.AgreedToRules, state.CurrentUrl, state.CurrentPage), Cmd_none()];
                }
                case 3: {
                    setAgreedToRules(agreed);
                    return [new State(state.CurrentUser, state.Boards, agreed, state.CurrentUrl, state.CurrentPage), Cmd_none()];
                }
                case 4: {
                    return [new State(state.CurrentUser, state.Boards, getAgreedToRules(), state.CurrentUrl, state.CurrentPage), Cmd_none()];
                }
                case 5: {
                    return [state, Cmd_none()];
                }
            }
        }
    }
}

export function subscription(state) {
    const matchValue = state.CurrentPage;
    switch (matchValue.tag) {
        case 8: {
            return Cmd_map((arg0) => (new Msg(5, arg0)), subscription_1(matchValue.fields[0]));
        }
        case 9: {
            return Cmd_map((arg0_1) => (new Msg(8, arg0_1)), subscribe(matchValue.fields[0]));
        }
        default: {
            return Cmd_none();
        }
    }
}

