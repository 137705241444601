import { Union, Record } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { union_type, unit_type, record_type, bool_type, string_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { AsyncOperationStatus$1$reflection, ResetPasswordError$reflection, SecureToken$reflection } from "../../../Shared.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class State extends Record {
    constructor(newPassword, newPasswordRepeat, networking, token) {
        super();
        this.newPassword = newPassword;
        this.newPasswordRepeat = newPasswordRepeat;
        this.networking = networking;
        this.token = token;
    }
}

export function State$reflection() {
    return record_type("ResetPassword.Types.State", [], State, () => [["newPassword", string_type], ["newPasswordRepeat", string_type], ["networking", bool_type], ["token", SecureToken$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetNewPassword", "SetNewPasswordRepeat", "ResetPassword"];
    }
}

export function Msg$reflection() {
    return union_type("ResetPassword.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ResetPasswordError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ResetPasswordError$reflection()]]]))]]]);
}

export function init(token) {
    return [new State("", "", false, token), Cmd_none()];
}

