import { Union, Record } from "../../../../Client/fable_modules/fable-library.3.7.5/Types.js";
import { union_type, record_type, bool_type, string_type } from "../../../../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { AsyncOperationStatus$1$reflection, LoginError$reflection, LoginResponse$reflection } from "../../../Shared.fs.js";
import { FSharpResult$2 } from "../../../../Client/fable_modules/fable-library.3.7.5/Choice.js";
import { Cmd_none } from "../../../../Client/fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class State extends Record {
    constructor(Username, Password, Networking) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Networking = Networking;
    }
}

export function State$reflection() {
    return record_type("Login.Types.State", [], State, () => [["Username", string_type], ["Password", string_type], ["Networking", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "SetPassword", "Login", "NavigateTo"];
    }
}

export function Msg$reflection() {
    return union_type("Login.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [LoginResponse$reflection(), LoginError$reflection()], FSharpResult$2, () => [[["ResultValue", LoginResponse$reflection()]], [["ErrorValue", LoginError$reflection()]]]))]], [["Item", string_type]]]);
}

export function $007CUserLoggedIn$007C_$007C(_arg1) {
    let pattern_matching_result, response;
    if (_arg1.tag === 2) {
        if (_arg1.fields[0].tag === 1) {
            if (_arg1.fields[0].fields[0].tag === 0) {
                pattern_matching_result = 0;
                response = _arg1.fields[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return response;
        }
        case 1: {
            return void 0;
        }
    }
}

export function init() {
    return [new State("", "", false), Cmd_none()];
}

