import { map, defaultArg } from "../../../../Client/fable_modules/fable-library.3.7.5/Option.js";
import { AsyncOperationStatus$1, CaptchaToken, Validation_isValidPassword, Validation_isValidUsername, Validation_isValidEmail } from "../../../Shared.fs.js";
import { createElement } from "react";
import { empty, singleton, append, delay, toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { Msg } from "./Types.fs.js";
import { singleton as singleton_1, cons, ofArray } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { isNullOrEmpty } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../../../Components/../../Client/fable_modules/Feliz.1.56.0/Interop.fs.js";
import react_hcaptcha from "@hcaptcha/react-hcaptcha";
import { config } from "../../../Config.fs.js";

export function render(state, dispatch) {
    let children_9, elms_1, elms, elms_3, elms_2, elms_5, elms_4, elms_7, elms_6, elms_8, props_26;
    let inputValid;
    const emailValid = defaultArg(map(Validation_isValidEmail, state.Email), true);
    inputValid = (((Validation_isValidUsername(state.Username) && Validation_isValidPassword(state.Password)) && (state.Password === state.PasswordRepeat)) && emailValid);
    const children_11 = singleton_1((children_9 = ofArray([createElement("h1", {
        children: ["Signup"],
    }), (elms_1 = ofArray([createElement("label", {
        children: "Username",
    }), (elms = toList(delay(() => append(singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Username"], ["value", state.Username], ["onChange", (ev) => {
        dispatch(new Msg(0, ev.target.value));
    }]])))))), delay(() => (((!isNullOrEmpty(state.Username)) && (!Validation_isValidUsername(state.Username))) ? singleton(createElement("span", {
        className: "has-text-danger",
        children: "username must be between 3-25 characters",
    })) : singleton(null)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = ofArray([createElement("label", {
        children: "Email (Optional)",
    }), (elms_2 = toList(delay(() => {
        let props_8;
        return append(singleton((props_8 = ofArray([["placeholder", "Email"], ["value", defaultArg(state.Email, "")], ["onChange", (ev_1) => {
            dispatch(new Msg(1, ev_1.target.value));
        }]]), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_8)))))), delay(() => {
            const matchValue = state.Email;
            if (matchValue == null) {
                return singleton(null);
            }
            else {
                const email_1 = matchValue;
                return ((!isNullOrEmpty(email_1)) && (!Validation_isValidEmail(email_1))) ? singleton(createElement("span", {
                    className: "has-text-danger",
                    children: "email must be in valid format",
                })) : singleton(null);
            }
        }));
    })), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_5 = ofArray([createElement("label", {
        children: "Password",
    }), (elms_4 = toList(delay(() => append(singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["placeholder", "Password"], ["value", state.Password], ["onChange", (ev_2) => {
        dispatch(new Msg(2, ev_2.target.value));
    }]])))))), delay(() => (((!isNullOrEmpty(state.Password)) && (!Validation_isValidPassword(state.Password))) ? singleton(createElement("span", {
        className: "has-text-danger",
        children: "password must be at least 8 characters",
    })) : singleton(null)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_7 = ofArray([createElement("label", {
        children: "Confirm Password",
    }), (elms_6 = toList(delay(() => append(singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["placeholder", "Confirm Password"], ["value", state.PasswordRepeat], ["onChange", (ev_3) => {
        dispatch(new Msg(3, ev_3.target.value));
    }]])))))), delay(() => (((!isNullOrEmpty(state.PasswordRepeat)) && (state.Password !== state.PasswordRepeat)) ? singleton(createElement("span", {
        className: "has-text-danger",
        children: "passwords must match",
    })) : singleton(null)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })), Interop_reactApi_1.createElement(react_hcaptcha, {
        sitekey: config.CaptchaSiteKey,
        onVerify: (token_1) => {
            dispatch(new Msg(4, new CaptchaToken(0, token_1)));
        },
    }), (elms_8 = singleton_1((props_26 = toList(delay(() => append(state.Networking ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["className", "is-link"]), delay(() => append(singleton(["disabled", !inputValid]), delay(() => append(singleton(["children", "Signup"]), delay(() => singleton(["onClick", (e) => {
        e.preventDefault();
        dispatch(new Msg(5, new AsyncOperationStatus$1(0)));
    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_26))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    }))]), createElement("form", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    })));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    });
}

