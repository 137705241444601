import { filter, empty as empty_1, cons, singleton as singleton_1, ofArray, map } from "../../../../Client/fable_modules/fable-library.3.7.5/List.js";
import { AsyncOperationStatusWith$2, UserModule_Role, User__InRole_7B3101B3, UserModule_Role__get_string } from "../../../Shared.fs.js";
import { createElement } from "react";
import { createObj } from "../../../../Client/fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../../../../Client/fable_modules/Feliz.Bulma.2.18.0/./ElementBuilders.fs.js";
import { toList } from "../../../../Client/fable_modules/fable-library.3.7.5/Set.js";
import { empty, singleton, append, delay, toList as toList_1 } from "../../../../Client/fable_modules/fable-library.3.7.5/Seq.js";
import { Interop_reactApi } from "../../../../Client/fable_modules/Feliz.1.56.0/./Interop.fs.js";
import { toShortDateString, fromDateTimeOffset } from "../../../../Client/fable_modules/fable-library.3.7.5/Date.js";
import { equals } from "../../../../Client/fable_modules/fable-library.3.7.5/Long.js";
import { Msg } from "./Types.fs.js";
import { isNullOrEmpty } from "../../../../Client/fable_modules/fable-library.3.7.5/String.js";

export function renderUser(user, state, dispatch) {
    const roles = map((role) => {
        switch (role.tag) {
            case 1: {
                const props_2 = ofArray([["className", "is-warning"], ["children", UserModule_Role__get_string(role)]]);
                return createElement("span", createObj(Helpers_combineClasses("tag", props_2)));
            }
            case 0: {
                const props_4 = ofArray([["className", "is-primary"], ["children", UserModule_Role__get_string(role)]]);
                return createElement("span", createObj(Helpers_combineClasses("tag", props_4)));
            }
            default: {
                const props = ofArray([["className", "is-danger"], ["children", UserModule_Role__get_string(role)]]);
                return createElement("span", createObj(Helpers_combineClasses("tag", props)));
            }
        }
    }, toList(user.Roles));
    const children_11 = toList_1(delay(() => {
        let children;
        return append(user.IsBanned ? singleton((children = singleton_1(createElement("del", {
            children: user.Username,
        })), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))) : singleton(createElement("td", {
            children: user.Username,
        })), delay(() => {
            let children_3;
            return append(singleton((children_3 = singleton_1(createElement("div", {
                className: "tags",
                children: Interop_reactApi.Children.toArray(Array.from(roles)),
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            }))), delay(() => {
                let children_5;
                return append(singleton((children_5 = singleton_1(createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", ofArray([["checked", user.EmailVerified], ["disabled", true]])))))), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_5)),
                }))), delay(() => {
                    let copyOfStruct;
                    return append(singleton(createElement("td", {
                        children: (copyOfStruct = fromDateTimeOffset(user.CreatedAt, 0), toShortDateString(copyOfStruct)),
                    })), delay(() => {
                        let copyOfStruct_1;
                        return append(singleton(createElement("td", {
                            children: (copyOfStruct_1 = fromDateTimeOffset(user.UpdatedAt, 0), toShortDateString(copyOfStruct_1)),
                        })), delay(() => {
                            let children_9, children_7;
                            return singleton((children_9 = singleton_1((children_7 = toList_1(delay(() => ((!equals(user.Id, state.CurrentUser.Id)) ? singleton(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["aria-haspopup", true], ["target", "modal-user-menu"], ["children", "Edit"], ["onClick", (e) => {
                                e.preventDefault();
                                dispatch(new Msg(5, user));
                                dispatch(new Msg(4, true));
                            }]]))))) : empty()))), createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                            }))), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_9)),
                            })));
                        }));
                    }));
                }));
            }));
        }));
    }));
    return createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    });
}

export function userMenu(state, dispatch) {
    const props_26 = toList_1(delay(() => append(singleton(["id", "modal-user-menu"]), delay(() => append((state.ShowUserMenu && (state.SelectedUser != null)) ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_8, elms_5, elms_4;
        return singleton((elems_8 = [createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms_5 = singleton_1((elms_4 = toList_1(delay(() => {
            let children_8, elems, elems_5, children_6;
            const matchValue = state.SelectedUser;
            if (matchValue == null) {
                return singleton(null);
            }
            else {
                const user = matchValue;
                return singleton((children_8 = ofArray([createElement("div", createObj(ofArray([["id", "mod-section"], ["className", "block"], (elems = toList_1(delay(() => {
                    let children;
                    return append(singleton((children = singleton_1(createElement("b", {
                        children: user.Username,
                    })), createElement("h1", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))), delay(() => {
                        let props_4, props_6;
                        return User__InRole_7B3101B3(user, new UserModule_Role(1)) ? singleton((props_4 = toList_1(delay(() => append(state.Networking ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["disabled", state.Networking]), delay(() => append(singleton(["id", "removeMod"]), delay(() => append(singleton(["children", "Remove Moderator Role"]), delay(() => singleton(["onClick", (e) => {
                            e.preventDefault();
                            dispatch(new Msg(3, new AsyncOperationStatusWith$2(0, [user.Id, UserModule_Role__get_string(new UserModule_Role(1))])));
                        }]))))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))) : singleton((props_6 = toList_1(delay(() => append(state.Networking ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["disabled", state.Networking]), delay(() => append(singleton(["id", "assignMod"]), delay(() => append(singleton(["children", "Assign Moderator Role"]), delay(() => singleton(["onClick", (e_1) => {
                            e_1.preventDefault();
                            dispatch(new Msg(2, new AsyncOperationStatusWith$2(0, [user.Id, UserModule_Role__get_string(new UserModule_Role(1))])));
                        }]))))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_6)))));
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["id", "ban"], ["className", "block"], (elems_5 = [(children_6 = toList_1(delay(() => append(singleton(createElement("h1", {
                    children: "Ban User",
                })), delay(() => {
                    let elms_1, elms;
                    return append((!state.IndefiniteBan) ? singleton((elms_1 = ofArray([createElement("label", {
                        className: "label",
                        children: "Ban End Date",
                    }), (elms = singleton_1(createElement("input", createObj(cons(["type", "date"], Helpers_combineClasses("input", empty_1()))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))) : empty(), delay(() => {
                        let elms_3, elms_2;
                        return singleton((elms_3 = singleton_1((elms_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-danger"], ["children", "Ban"]]))))), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                        }))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                        })));
                    }));
                })))), createElement("form", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                })));
            }
        })), createElement("div", {
            className: "box",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        })), createElement("button", createObj(Helpers_combineClasses("modal-close", ofArray([["className", "is-large"], ["onClick", (e_2) => {
            e_2.preventDefault();
            dispatch(new Msg(5, void 0));
            dispatch(new Msg(4, false));
        }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]));
    }))))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_26)));
}

export function renderUsers(users, state, dispatch) {
    let elems_1, children_2, children, elems;
    const props_9 = ofArray([["className", "is-fullwidth"], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        children: "Username",
    }), createElement("th", {
        children: "Roles",
    }), createElement("th", {
        children: "Email Verified?",
    }), createElement("th", {
        children: "Created",
    }), createElement("th", {
        children: "Updated",
    }), createElement("th", {
        children: "Actions",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("tbody", createObj(singleton_1((elems = map((user) => renderUser(user, state, dispatch), users), ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_9)));
}

export function searchbar(state, dispatch) {
    let elems_1, elms;
    const props_3 = ofArray([["className", "has-addons"], (elems_1 = [(elms = singleton_1(createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["value", state.Search], ["placeholder", "Find a user"], ["onChange", (ev) => {
        dispatch(new Msg(1, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_3)));
}

export function filterUsers(users, search) {
    if (isNullOrEmpty(search)) {
        return users;
    }
    else {
        return filter((user) => (user.Username.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0), users);
    }
}

export function render(state, dispatch) {
    const elms = toList_1(delay(() => {
        let children;
        const matchValue = state.Users;
        switch (matchValue.tag) {
            case 1: {
                return singleton(createElement("b", {
                    children: "Loading...",
                }));
            }
            case 2: {
                return singleton((children = ofArray([searchbar(state, dispatch), renderUsers(filterUsers(matchValue.fields[0], state.Search), state, dispatch), userMenu(state, dispatch)]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }
            default: {
                return singleton(null);
            }
        }
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

